import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import { Box, Grid, Stack } from '@mui/material';
// eslint-disable-next-line
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi'; // eslint-disable-next-line
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, dateFormat, openDocumentInNewtab, removeLoader } from '../../../../utils/utils'; // eslint-disable-next-line
import Button from '../../../../components/customButton/Button';
import LocalStorage from '../../../../utils/LocalStorage';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../assets/svg/File.svg'
import noDataFound from '../../../../assets/svg/NoDataFoundIcon.svg'
import FileInput from '../../../../components/muiFileInput/FileInput';
import Input from '../../../../components/input/Input';
import CommonApi from '../../../../apis/CommonApi';
import { isValid, validate_ssn_empty, validate_emptyField } from '../../../../components/Validation';
import LoadingScreen from './LoadingScreen';
import OnBoardApi from '../../../../apis/admin/employees/OnBoardApi';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../assets/svg/placementPop.svg'
import Userplus from '../../../../assets/svg/plus.svg';
import changesSent from '../../../../assets/svg/changesSent.svg'

export default function SSN(props) {
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);
    // eslint-disable-next-line
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setCurrent(event)
    };
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listi94, setListi94] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listWork, setListWork] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [current, setCurrent] = useState("education");
    const [employee, setEmployee] = useState(props.employee)
    const [formOpen, setformOpen] = useState(false)
    const [error, setError] = useState({ document_number: "" });
    const [clearPopup1, setclearPopup1] = useState(false);
    const [clearPopup2, setclearPopup2] = useState(false);
    const [clearPopup3, setclearPopup3] = useState(false);
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [state, setState] = useState(
        {
            document_type_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            documents_deleted_ids: [],
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: ""
                }
            ]
        }
    );

    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if (props.documentPosition !== 'work' && props.documentPosition !== 'education') {
            if (props.formEdit != true) {
                getDriving();
            }
        }
        // eslint-disable-next-line
    }, [props.documentPosition])


    const getDriving = () => {
        setLoading(true)
        setError({
            ...error,
            document_number: ''
        })
        
        OnBoardApi.documentIndexConsultant(LocalStorage.uid(), props.employee.id, LocalStorage.getAccessToken()).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length > 0) {
                        let data = response.data.data
                        let b = data.find((i) => i.document_type_id == 2)
                        // console.log(b, 'b')
                        if (b !== undefined) {
                            setList([b]);
                            if (b.documents.length === 0) {
                                let data = b
                                data.documents = [
                                    {
                                        id: "",
                                        new_document_id: "",
                                        name: ""
                                    }
                                ]
                                setState({ ...data })
                            }
                            else {
                                setState(b)
                            }
                        }
                        else {
                            setState({
                                ...state,
                                id: '',
                                document_number: props.employee.ssn,
                                documents: [
                                    {
                                        id: "",
                                        new_document_id: "",
                                        document_name: ""
                                    }
                                ]
                            })
                            setList({
                                document_type_id: "",
                                id: '',
                                valid_from: "",
                                valid_till: "",
                                document_number: "",
                                documents_deleted_ids: [],
                                documents: [
                                    {
                                        id: "",
                                        new_document_id: "",
                                        document_name: ""
                                    }
                                ]
                            })
                        }
                    }
                    else {
                        setState({
                            ...state,
                            id: '',
                            document_number: props.employee.ssn,
                            documents: [
                                {
                                    id: "",
                                    new_document_id: "",
                                    document_name: ""
                                }
                            ]
                        })
                        setList({
                            document_type_id: "",
                            id: '',
                            valid_from: "",
                            valid_till: "",
                            document_number: "",
                            documents_deleted_ids: [],
                            documents: [
                                {
                                    id: "",
                                    new_document_id: "",
                                    document_name: ""
                                }
                            ]
                        })
                    }
                } else {
                    addErrorMsg(response.data.message);
                    setLoading(false)
                }
            }, 400)
        });
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const uploadDocs = (value) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        CommonApi
            .documentUpload("personal-document", formData, LocalStorage.getAccessToken())
            .then((response) => {
                if (response.data.statusCode == 1003) {
                    let docInfo = response.data.data
                    const data = {
                        url: response.data.data.document_url,
                        id: response.data.data.id
                    }
                    CommonApi.ocrDocumentUpload('ssn-data', data).then((res) => {
                        if (res.data.statusCode === 1003) {
                            // state.document_number = res.data.data.passport_number
                            // state.valid_from = moment(res.data.data.date_of_issue, 'DD/MM/YYYY').format(dateFormat())
                            // state.valid_till = moment(res.data.data.date_of_expiry, 'DD/MM/YYYY').format(dateFormat())
                            state.documents[0].new_document_id = response.data.data.id
                            state.documents[0].document_url = response.data.data.document_url
                            state.documents[0].document_name = value.target.files[0].name
                            state.document_number = res.data.data.social_security_number ? res.data.data.social_security_number : state.document_number
                            state.clear_all = false
                            setState({ ...state })
                            handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                            removeLoader()
                        } else {

                            removeLoader()
                            state.documents[0].new_document_id = response.data.data.id
                            state.documents[0].document_url = response.data.data.document_url
                            state.documents[0].document_name = value.target.files[0].name
                            state.clear_all = false
                            setState({ ...state })
                            handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                        }
                    })
                } else {

                    addErrorMsg(response.data.message);
                    removeLoader()
                }
            });
    };

    const handleChange = (e) => {
        state.clear_all = false
        if (e.target.name == 'document_number') {
            convertFormat(e);
            checkEmail(e.target.value, 'ssn')
        }
        else {
            setState({
                ...state, [e.target.name]: e.target.value
            })
        }

        handleValidations(e.target);
    };

    const checkEmail = (args, val) => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: props.employee.id
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: props.employee.id
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: props.employee.id
            }
            EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (val == 'email') {
                            setError({
                                ...error,
                                email_id: "Email ID already exists"
                            })

                        }
                        else if (val == 'mobile') {
                            setError({
                                ...error,
                                contact_number: "Mobile Number already exists"
                            })
                        }
                        else {
                            setError({
                                ...error,
                                document_number: "SSN already exists"
                            })
                        }
                    }
                }
                else {
                    addErrorMsg(res.data.message)
                }
            });
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }

    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`
                }, handleValidations(e));
            if (name == 'ssn') {
                if (last == '0000') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`
                }, handleValidations(e));
            if (name == 'ssn') {
                if (middle == '00') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input
                }, handleValidations(e));
            if (name == 'ssn') {
                if (first == '000' || first == '666' || first == '900') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
    }


    const handleValidations = (input) => {
        // let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "document_number":
                err.document_number = validate_ssn_empty(input.value);
                break;
            default:
                break;
        }
        setError(err);
    };

    const handleDocumentValidations = (input) => {
        let err = error;
        switch (input.name) {
            case "documents":
                err.documents = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const deleteDoc = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].document_name = ''
        state.clear_all = false
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
    }

    const clearAll = () => {
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({
            ...state,
            document_type_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    document_name: ""
                }
            ]
        })
        setError({
            ...error,
            document_number: ''
        })
        setclearPopup(false)
    }


    const submitForm = (args) => {
        if (error.document_number !== '') {
            return true
        }
        let data = {
            document_id: state.documents[0].new_document_id,
            document_number: state.document_number,
        }
        if (state.id !== '') {
            state['request_id'] = LocalStorage.uid();
            state['employee_id'] = props.employee.id;
            state['document_type_id'] = 2
            if (isValid(data)) {
                state['clear_all'] = true;
            }
            if (state.clear_all == false && state.document_number === '') {
                error.document_number = 'This field is required'
                setError({ ...error })
            }
            else {
                state.entity = 'ssn-documents'
                if (args) {
                    setclearPopup2(true)
                }
                else {
                    OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                        if (res.data.statusCode === 1003) {
                            addSuccessMsg(`SSN updated Successfully`);
                            setclearPopup1(true)
                            setformOpen(false);
                            getDriving();
                            props.setformEdit(false)
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }
            }

        }
        else {
            if (isValid(data)) {
                setformOpen(false);
                return true
            }
            else {
                if (state.document_number === '') {
                    error.document_number = 'This field is required'
                    setError({ ...error })
                }
                else {
                    state['request_id'] = LocalStorage.uid()
                    state['employee_id'] = props.employee.id
                    state['document_type_id'] = 2
                    state.entity = 'ssn-documents'
                    if (args) {
                        setclearPopup2(true)
                    }
                    else {
                        OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode === 1003) {
                                addSuccessMsg(`SSN Added Successfully`);
                                setformOpen(false);
                                setclearPopup1(true)
                                getDriving();
                                props.setformEdit(false)
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                }
            }
        }
    }
    const editDetails = () => {
        if (props.employee.pending_requests.ssn_documents) {
            setclearPopup3(true)
        }
        else {
            setformOpen(true); LocalStorage.setEditForm('edit'); props.setformEdit(true)
        }
    }

    return (
        <Grid container>
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                            <Box p={1} pl={2} pt={2}>
                                <Text largeBlue>
                                    Social Security Number (SSN)
                                </Text>
                            </Box>
                        </Grid>
                        {
                            formOpen ? '' :
                                employee && employee.is_off_boarding_initiated === false && list.length > 0 ?
                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                        <Box p={1} pr={0}>
                                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { editDetails() }} />
                                        </Box>
                                    </Grid>
                                    : ''
                        }
                        {
                            formOpen ? '' :
                                list.length > 0 ?
                                    <Grid item container lg={12}>

                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    SSN Number
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].document_number ? list[0].document_number : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={3} md={6} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    SSN Document
                                                </Text>
                                                {list[0].documents.length > 0 && list[0].documents[0].document_name !== '' ?
                                                    list[0].documents[0].document_name ?
                                                        <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(list[0].documents[0], list[0].documents[0].document_url) }}>
                                                            <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                            <Text mediumBlue>
                                                                <BlackToolTip arrow placement='top' title={list[0].documents[0].document_name}>
                                                                    {list[0].documents[0].document_name.slice(0, 16)} {list[0].documents[0].document_name.length > 16 ? '...' : ''}
                                                                </BlackToolTip>
                                                            </Text>
                                                        </Stack> : "-"
                                                    : '-'
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text largeBlack>No Documents Uploaded</Text>
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload SSN document for this employee by </Text>
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={1}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>clicking on the below button.</Text>
                                        </Grid>
                                        {
                                            employee.is_off_boarding_initiated === false &&
                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={3}>
                                                <Button finishFilledQuicksandLargeWidth onClick={() => { editDetails() }}> <img src={Userplus} alt='plus' /> Add SSN Details</Button>
                                            </Grid>
                                        }
                                    </Grid>
                        }
                        {formOpen ?
                            <Grid container p={2} pl={0}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <FileInput
                                            name={"Passport"}
                                            FileName={state.documents[0].document_name}
                                            handleChange={uploadDocs}
                                            label={"SSN Document"}
                                            documentUrl={state.documents[0].document_url}
                                            handleDelete={() => deleteDoc()}
                                            actionState={state.documents[0].document_name ? 1 : ''}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'document_number',
                                                value: state.document_number,
                                                type: 'text',
                                                inputProps: { maxLength: 11 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'SSN Number'}
                                            error={error.document_number}
                                        />
                                        <Text red>{error.document_number}</Text>
                                    </Box>
                                </Grid>


                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} pl={2}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3}>
                                        <Button cancelOutlineQuicksand onClick={() => { setformOpen(false); getDriving(); props.setformEdit(false) }}>Cancel</Button>
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3} justifyContent={'flex-end'}>
                                        <Stack direction={'row'} >
                                            <Button clearAllRed onClick={() => { setclearPopup(true) }}>Clear all</Button>
                                            <Button smallBlue onClick={() => { submitForm('popup') }}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }
                        <Grid container>
                            <Box height={'5vh'} />
                        </Grid>
                    </Grid>
            }
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup2} setOpenPopup={setclearPopup2} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to make this changes?</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup2(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledQuicksand onClick={() => { submitForm(); setclearPopup2(false); }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup1} setOpenPopup={setclearPopup1} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changesSent} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Changes Sent For Approval</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>"Thank you for updating your details. Your changes</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>have been submitted for approval to your employer. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Once approved, the new details will be displayed here. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Click 'OK' to proceed."</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup1(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>You have recently updated your details, Please</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>wait until your employer has taken any action to</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>edit</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup3(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>

    );
}