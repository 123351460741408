import { Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material';
import React from 'react';
import deleteAlert from "../../assets/svg/placementPop.svg";
import closeImg from '../../assets/svg/crossIcon.svg';
import Button from '../../components/customButton/Button';
import { makeStyles } from '@mui/styles';
import LoadingButton from '../customButton/LoadingButton';
import Text from '../customText/Text';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={"up"} ref={ref} {...props} />;
});

const deleteStyles = makeStyles(() => ({
    paper: {
        position: 'absolute',
        borderRadius: '8px 8px 8px 8px !important',
        background: '#FFFFFF !important',
        width: "400px !important"
    },
}));

function ReusableDelete(props) {
    const { open, setOpen, icon } = props;

    const classes = deleteStyles();
    return (
        <Dialog classes={{ paper: classes.paper }} onClose={() => { setOpen(false) }} open={open} TransitionComponent={Transition}>
            <DialogTitle >
                <div style={{ display: 'flex', justifyContent: "end" }}>
                    {icon && <img src={closeImg} alt='close' onClick={() => { setOpen(false) }} style={{ cursor: 'pointer' }} />}
                </div>
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", padding: "30px" }}>
                <Grid container>
                    <Grid item md={12} sm={12}>
                        <img src={deleteAlert} alt="deleteAlert" />
                    </Grid>
                    <Grid item md={12} sm={12} pt={2}>
                        <Text popupHead1 >{props.navigate ? "Couldn't Delete!" : "Are You Sure?"}</Text>
                    </Grid>
                    <Grid item md={12} sm={12} pt={2}>
                        {
                            props.clear ?
                                <Text offBoardBodyFont>Do You Really Wish To Clear the Data.</Text>
                                :
                                props.navigate ?
                                    <Text offBoardBodyFont>Currently there are employees assigned to <br />this role, to delete this role please remove <br />users first. </Text>
                                    :
                                    props.reject ?
                                        <Text offBoardBodyFont>Once the document is rejected this will not be saved you should manually upload in {props.rejectcontent} details section.</Text>
                                        :
                                        props.content ?
                                            <Text offBoardBodyFont>{props.content}</Text>
                                            :
                                            <Text offBoardBodyFont>Do You Really Wish To Delete.</Text>
                        }

                    </Grid>
                    {
                        props.button ?
                            <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>
                                <Button onClick={() => { setOpen(false) }} outlineBlueMedium1 sx={{ width: "80px !important", height: "42px !important", borderRadius: "8px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }}>Cancel</Button>
                                {
                                    props.navigate ?
                                        <Button onClick={props.onClick} deleteButton sx={{ background: "#0C75EB !important", height: "42px !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, width: "206px !important", }}>Go To Internal Employees</Button>
                                        :
                                        <LoadingButton saveBtn sx={{ width: "88px !important", height: "42px !important", font: "14px Quicksand !important", fontWeight: `${700} !important` }} loading={props.loading} onClick={props.onClick}>Yes</LoadingButton>
                                }
                            </Grid> :
                            props.timesheet ?
                                <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>
                                    <Button onClick={() => { setOpen(false) }} outlineBlueMedium1 sx={{ width: "124px !important", borderRadius: "8px !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, minHeight: "44px !important" }}>Cancel</Button>
                                    <LoadingButton saveBtn sx={{ width: "124px !important", height: "44px !important", font: "15px Quicksand !important", fontWeight: `${400} !important` }} loading={props.loading} onClick={props.onClick}>Delete</LoadingButton>
                                </Grid>
                                :
                                <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>
                                    <Button onClick={() => { setOpen(false) }} outlineBlueMedium1 sx={{ width: "124px !important", borderRadius: "8px !important", font: props.deregister ? "18px Quicksand !important" : "14px Quicksand !important", fontWeight: `${700} !important`, minHeight: "44px !important" }}>{props.deregister ? "Cancel" : "No"}</Button>
                                    {
                                        props.deregister ?
                                            <LoadingButton saveBtn sx={{ width: "124px !important", height: "44px !important", font: "18px Quicksand !important", fontWeight: `${700} !important` }} loading={props.loading} onClick={props.onClick}>Confirm</LoadingButton> :
                                            <LoadingButton onClick={props.onClick} loading={props.loading} deleteButton sx={{ background: "#0C75EB !important", font: "14px Quicksand !important", fontWeight: `${700} !important`, minWidth: "135px !important", }}> {props.delete ? "Yes" : props.clear ? "Yes, Clear" : props.reject ? "Yes, Reject" : props.change ? "Yes, Change" : "Yes, Delete"}</LoadingButton>
                                    }
                                </Grid>

                    }

                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default ReusableDelete