import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Breadcrumbs, Slide, DialogContent, Dialog, Stack, Avatar, } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import Text from '../../../components/customText/Text';
import Datepicker from '../../../components/datePicker/Date';
import { BlackToolTip, addLoader, addSuccessMsg, capitalizeAndAddSpace, dateFormat, removeLoader } from '../../../utils/utils';
import moment from "moment";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from '../../../utils/LocalStorage';
import { addErrorMsg, addWarningMsg } from '../../../utils/utils';
import { isValid, validate_emptyField } from "../../../components/Validation";
import Select from "../../../components/select/Select";
import Dropzone from "react-dropzone";
import { blue } from "../../../theme";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReusableDelete from "../../../components/reuablePopup/ReusableDelete";
import TimeSheetStyles from "./TimeSheetStyles";
import { validate_selectField } from "../../../components/Validation";
import Input from "../../../components/input/Input";
import TimeSheetTable from "./TimeSheetTable";
import dayjs from 'dayjs'
import LoadingButton from "../../../components/customButton/LoadingButton";
import Button from "../../../components/customButton/Button";
import PlacementApi from "../../../apis/admin/placements/PlacementApi";
// import infoCircle from "../../../assets/images/info-circle.png";
import newLoader from "../../../assets/gif/japfuLoader.gif";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EmployeesApi from "../../../apis/admin/employees/EmployeesApi";
import offBoardSave from "../../../assets/svg/offBoardSave.svg";
import deleteAlert from "../../../assets/svg/dleteAlert.svg"
import TimesheetApi from "../../../apis/admin/timesheets/TimesheetApi";
import disableVerified from '../../../assets/client/disableVerified.svg';
import Component87 from '../../../assets/svg/Component87.svg';
import timesheet_submit from '../../../assets/svg/timesheet_submit.svg';
import rejectSVg from "../../../assets/svg/placementPop.svg";
import timesheet_reject from "../../../assets/svg/timesheet_reject.svg";
import timesheet_approved from "../../../assets/svg/timesheet_approved.svg";
import employee_notmatch from "../../../assets/svg/employee_notmatch.svg";
import datefetched from "../../../assets/svg/datafetched.svg";
import CircleIcon from '@mui/icons-material/Circle';
import { domain } from "../../../config/Domain";
import DashboardAPI from "../../../apis/admin/DashboardAPI";
import rightArrow from '../../../assets/timeSheets/white-arrow.svg';
import ReusablePopup from "../../../components/reuablePopup/ReusablePopup";
import emptyPrompts from '../../../assets/timeSheets/zero-prompts.svg';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddTimesheet() {

    const classes = TimeSheetStyles();
    const navigate = useNavigate();
    const stateId = useLocation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({});
    const [data, setData] = useState([]);
    const [placements, setPlacements] = useState([]); // eslint-disable-next-line
    const [employees, setEmployees] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [employee, setEmployee] = useState("");
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteTimesheet, setDeleteTimesheet] = useState(false);
    const [deleteLoad, setDeleteLoad] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(false);
    const [loading, setLoading] = useState(false);
    const [navigateOpen, setNavigateOpen] = useState(false);
    const [openExists, setOpenExists] = useState(false);
    const [placementId, setPlacementId] = useState('');
    const [openSubmit, setOpenSubmit] = useState(false);
    const [edit, setEdit] = useState(false);
    const [placementClear, setPlacementClear] = useState(false);
    const [approvedOpen, setApprovedOpen] = useState(false);
    const [capture, setCapture] = useState(false);
    const [existData, setExistData] = useState({ id: "", status: "" });
    const [timesheetEndDate, setTimesheetEndDate] = useState("");
    const [ocrOpen, setOcrOpen] = useState(false);
    const [documentOpen, setDocumentOpen] = useState(false);
    const [ocrTimesheetData, setOcrTimesheetData] = useState([]);
    const [employeeOpen, setEmployeeOpen] = useState(false);
    const [employeeNotFound, setEmployeeNotFound] = useState(false);
    const [openZero, setOpenZero] = useState(false);
    const [ocrData, setOcrData] = useState(null);
    const [values, setValues] = useState({
        request_id: LocalStorage.uid(),
        employee_id: LocalStorage.getUserData().login_id,
        employee_name: LocalStorage.getUserData().full_name,
        placement_id: "",
        client_id: "",
        client_name: "",
        timesheet_cycle: "",
        timesheet_start_day: "",
        timesheet_start_date: "",
        end_client_id: "",
        end_client_name: "",
        default_hours: "08:00",
        is_timesheet_attachment_mandatory: false,
        start_date: "",
        end_date: "",
        comments: "",
        total_billable_hours: "",
        is_editable: true,
        is_submit: true,
        is_delete: false,
        total_hours: "",
        total_ot_hours: "",
        weekly: [],
        documents: []
    });

    const [planDetails, setplanDetails] = useState({
        ai_timesheet_access_available_credits: ''
    });
    const [captureLoader, setCaptureLoader] = useState(false);

    const getPlanCheck = () => {
        addLoader(true);
        setCaptureLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setCaptureLoader(false);
                setplanDetails(res.data)
            }
        })
    }

    useEffect(() => {
        if (stateId.state !== null && stateId.state.id !== "") {
            getTimeSheetDataWithId(stateId.state.id);
        } else {
            setEdit(true);
            if (!capture) {
                getDatesRange(values.start_date, values.end_date, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
            }
        }
        getPlanCheck();
        // eslint-disable-next-line
    }, [values.start_date, values.end_date]);

    useEffect(() => {
        getEmployeesDropDown();
        getEmployeePlacementInfo(values.employee_id);
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        if (e.target.name === "employee_id") {
            setPlacementId('');
            setPlacementClear(false);
            if (values.employee_id !== "" && (values.placement_id !== "" || values.start_date !== "" || values.end_date !== "" || values.documents.length !== 0 || values.weekly.length > 0)) {
                setEmployee(e.target.value);
                setAlertOpen(true);
            } else {
                if (e.target.value !== "") {
                    getEmployeePlacementInfo(e.target.value);
                } else {
                    setPlacements([]);
                }
                values[e.target.name] = e.target.value;
                setValues({ ...values }, handleValidate(e));
            }
        }
        else if (e.target.name === "placement_id") {
            if (values.placement_id !== "") {
                setPlacementClear(true);
                setPlacementId(e.target.value);
                setAlertOpen(true);
            } else {
                setPlacementClear(false);
                if (e.target.value !== "") {
                    placementSetting(e.target.value); // calling placementSetting func for filtering by placementId
                }
                values[e.target.name] = e.target.value;
                setValues({ ...values }, handleValidate(e));
            }
            setError(validate_selectField(e.target.name, error));
        } else if (e.target.name === "end_client_name" || e.target.name == "client_name" || e.target.name == "timesheet_cycle") {
            return false;
        }
        else {
            setValues({ ...values, [e.target.name]: e.target.value });
            handleValidate(e);
        }
    };
    // table inside billable hours time handle change
    const tableHandleChange = (value, tableIndex, rowIndex) => {
        let input = value.replace(/\D/g, "").substring(0, 5);
        const first = input.substring(0, 2);
        const second = input.substring(2, 5);

        // billable_hours
        if (input.length == 2) {
            if (first > 23) {
                var hou = first.toString();
                var afterSplit = hou.split('');
                if (afterSplit[afterSplit.length - 1] == "0") {
                    var reverseHou = hou.split('').reverse().join('');
                    values.weekly[tableIndex].timesheet[rowIndex].billable_hours = reverseHou;
                } else {
                    afterSplit.pop(); // Remove last element
                    afterSplit.unshift('0'); // Insert '0' at the beginning
                    values.weekly[tableIndex].timesheet[rowIndex].billable_hours = afterSplit.join('');
                }
            } else {
                values.weekly[tableIndex].timesheet[rowIndex].billable_hours = input;
            }
            setValues({ ...values });
        }
        else if (input.length > 2) {
            var mm = parseInt(second);
            if (mm > 59) {
                if (first < 23) {
                    var sec = second - 60;
                    var fOne = parseInt(first) + 1;
                    if (fOne < 10) {
                        if (sec < 10) {
                            values.weekly[tableIndex].timesheet[rowIndex].billable_hours = `0${fOne}:0${sec}`;
                        } else {
                            values.weekly[tableIndex].timesheet[rowIndex].billable_hours = `0${fOne}:${sec}`;
                        }
                    } else {
                        values.weekly[tableIndex].timesheet[rowIndex].billable_hours = `${fOne}:${sec}`;
                    }
                } else {
                    values.weekly[tableIndex].timesheet[rowIndex].billable_hours = `${first}:${59}`;
                }
            } else {
                values.weekly[tableIndex].timesheet[rowIndex].billable_hours = `${first}:${second}`;
            }
        } else if (input.length >= 0) {
            var hh = parseInt(input);
            if (hh > 23) {
                if (parseInt(values.weekly[tableIndex].timesheet[rowIndex].billable_hours) <= 9) {
                    values.weekly[tableIndex].timesheet[rowIndex].billable_hours = values.weekly[tableIndex].timesheet[rowIndex].billable_hours + "0";
                } else {
                    values.weekly[tableIndex].timesheet[rowIndex].billable_hours = "23";
                }
            } else {
                values.weekly[tableIndex].timesheet[rowIndex].billable_hours = input;
            }
        }

        let billableArray = [];
        let totalArray = [];
        assignEmptyOtTime();

        if (input !== "" && values.weekly[tableIndex].timesheet[rowIndex].billable_hours.length >= 2) {
            values.weekly[tableIndex].timesheet[rowIndex].billable_hours = addTimes(values.weekly[tableIndex].timesheet[rowIndex].ot_hours !== "" ? values.weekly[tableIndex].timesheet[rowIndex].ot_hours : "00:00", values.weekly[tableIndex].timesheet[rowIndex].billable_hours)
            setValues({ ...values });
        }
        if (input.length === 3 && values.weekly[tableIndex].timesheet[rowIndex].total_hours == "24:00") {
            values.weekly[tableIndex].timesheet[rowIndex].billable_hours = "";
            setValues({ ...values });
        }

        for (let i = 0; i < values.weekly[tableIndex].timesheet.length; i++) {
            if (values.weekly[tableIndex].timesheet[i].billable_hours.length === 0) {
                billableArray.push("00:00");
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].ot_hours, "00:00");
            } else if (values.weekly[tableIndex].timesheet[i].billable_hours.length === 1) {
                billableArray.push(values.weekly[tableIndex].timesheet[i].billable_hours + "0:00");
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].ot_hours, values.weekly[tableIndex].timesheet[i].billable_hours + "0:00");
            } else if (
                values.weekly[tableIndex].timesheet[i].billable_hours.length === 2
            ) {
                billableArray.push(values.weekly[tableIndex].timesheet[i].billable_hours + ":00");
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].ot_hours, values.weekly[tableIndex].timesheet[i].billable_hours + ":00");
            } else {
                billableArray.push(values.weekly[tableIndex].timesheet[i].billable_hours);
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].ot_hours, values.weekly[tableIndex].timesheet[i].billable_hours);
            }

            totalArray.push(values.weekly[tableIndex].timesheet[i].total_hours);
        }

        values.weekly[tableIndex].billable_hours = globalTimeCalculation(billableArray);
        values.weekly[tableIndex].total_hours = globalTimeCalculation(totalArray);

        setValues({ ...values });
        timeSheetTotalCal(values);
    };
    // table inside ot hours time handle change
    const otHoursHandleChange = (value, tableIndex, rowIndex) => {
        let input = value.replace(/\D/g, "").substring(0, 5);
        const first = input.substring(0, 2);
        const second = input.substring(2, 5);

        if (input.length == 2) {
            if (first > 23) {
                var hou = first.toString();
                var afterSplit = hou.split('');
                if (afterSplit[afterSplit.length - 1] == "0") {
                    var reverseHou = hou.split('').reverse().join('');
                    values.weekly[tableIndex].timesheet[rowIndex].ot_hours = reverseHou;
                } else {
                    afterSplit.pop(); // Remove last element
                    afterSplit.unshift('0'); // Insert '0' at the beginning
                    values.weekly[tableIndex].timesheet[rowIndex].ot_hours = afterSplit.join('');
                }
            } else {
                values.weekly[tableIndex].timesheet[rowIndex].ot_hours = input;
            }
            setValues({ ...values });
        }
        else if (input.length > 2) {
            var mm = parseInt(second);
            if (mm > 59) {
                if (first < 23) {
                    var sec = second - 60;
                    var fOne = parseInt(first) + 1;
                    if (fOne < 10) {
                        if (sec < 10) {
                            values.weekly[tableIndex].timesheet[rowIndex].ot_hours = `0${fOne}:0${sec}`;
                        } else {
                            values.weekly[tableIndex].timesheet[rowIndex].ot_hours = `0${fOne}:${sec}`;
                        }
                    } else {
                        values.weekly[tableIndex].timesheet[rowIndex].ot_hours = `${fOne}:${sec}`;
                    }
                } else {
                    values.weekly[tableIndex].timesheet[rowIndex].ot_hours = `${first}:${59}`;
                }
            } else {
                // if (first > 23) {
                //     var hours = first.toString();
                //     var reverseHours = hours.split('').reverse().join('');
                //     values.weekly[tableIndex].timesheet[rowIndex].ot_hours = `${reverseHours}:${second}`;
                // } else {
                values.weekly[tableIndex].timesheet[rowIndex].ot_hours = `${first}:${second}`;
                // }
            }
            setValues({ ...values });
        }
        else if (input.length >= 0) {
            var hh = parseInt(input);
            if (hh > 23) {
                if (parseInt(values.weekly[tableIndex].timesheet[rowIndex].ot_hours) <= 9) {
                    values.weekly[tableIndex].timesheet[rowIndex].ot_hours = values.weekly[tableIndex].timesheet[rowIndex].ot_hours + "0";
                } else {
                    values.weekly[tableIndex].timesheet[rowIndex].ot_hours = "23";
                }
            } else {
                values.weekly[tableIndex].timesheet[rowIndex].ot_hours = input;
            }
            setValues({ ...values });
        }
        let otArray = [];
        let totalArray = [];
        assignEmptyBillableTime();
        if (input !== "" && values.weekly[tableIndex].timesheet[rowIndex].ot_hours.length >= 2) {
            values.weekly[tableIndex].timesheet[rowIndex].ot_hours = addTimes(values.weekly[tableIndex].timesheet[rowIndex].billable_hours !== "" ? values.weekly[tableIndex].timesheet[rowIndex].billable_hours : "00:00", values.weekly[tableIndex].timesheet[rowIndex].ot_hours)
            setValues({ ...values });
        }
        if (input.length === 3 && values.weekly[tableIndex].timesheet[rowIndex].total_hours == "24:00") {
            values.weekly[tableIndex].timesheet[rowIndex].ot_hours = "";
            setValues({ ...values });
        }

        for (let i = 0; i < values.weekly[tableIndex].timesheet.length; i++) {
            if (values.weekly[tableIndex].timesheet[i].ot_hours.length === 0) {
                otArray.push("00:00");
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].billable_hours, "00:00");
            } else if (values.weekly[tableIndex].timesheet[i].ot_hours.length === 1) {
                otArray.push(values.weekly[tableIndex].timesheet[i].ot_hours + "0:00");
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].billable_hours, values.weekly[tableIndex].timesheet[i].ot_hours + "0:00");
            } else if (values.weekly[tableIndex].timesheet[i].ot_hours.length === 2) {
                otArray.push(values.weekly[tableIndex].timesheet[i].ot_hours + ":00");
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].billable_hours, values.weekly[tableIndex].timesheet[i].ot_hours + ":00");
            } else {
                otArray.push(values.weekly[tableIndex].timesheet[i].ot_hours);
                values.weekly[tableIndex].timesheet[i].total_hours = diffTime(values.weekly[tableIndex].timesheet[i].billable_hours, values.weekly[tableIndex].timesheet[i].ot_hours);
            }
            totalArray.push(values.weekly[tableIndex].timesheet[i].total_hours);
        }
        values.weekly[tableIndex].ot_hours = globalTimeCalculation(otArray);
        values.weekly[tableIndex].total_hours = globalTimeCalculation(totalArray);

        setValues({ ...values });
        timeSheetTotalCal(values);
    };

    const addTimes = (timeOne, timeTwo) => {
        const [hours1, minutes1] = timeOne.split(":").map(Number);
        const [hours2, minutes2] = timeTwo.split(":").map(Number);
        let totalHours = hours1 + hours2;
        let totalMinutes = minutes1 + minutes2;
        if (totalMinutes >= 60) {
            totalHours += Math.floor(totalMinutes / 60);
            totalMinutes %= 60;
        }
        if (totalHours >= 24) {
            totalHours = 24 - hours1;
            totalMinutes = 0 - minutes1;
            if (totalMinutes < 0) {
                totalHours--; // Reduce one hour
                totalMinutes += 60; // Add 60 minutes
            }
            addErrorMsg("You cant add more than 24 hours in a day");
            const newTime = `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
            return newTime;
        } else {
            return timeTwo;
        }
    }

    const handleSubmit = (e) => {
        let errors = formValidations();
        if (isValid(errors)) {
            setLoading(true);
            assignEmptyOtTime();
            assignEmptyBillableTime(); // calling assign for empty values
            timeSheetTotalCal(values); // calling time sheet total calculation func
            if (stateId.state !== null && stateId.state.id !== "") {
                var newState = values;
                newState.approved_status = 1;
                newState["request_id"] = LocalStorage.uid();
                setValues({ ...newState });
                updateTimeSheet(values);
            } else {
                storeTimeSheet(values); // calling storeTimeSheet api func
            }
            if (capture) {
                handleCaptureOcrActivity();
            }
        } else {
            let err = { error };
            err = errors;
            setError(err);
            addWarningMsg("Please fill all mandatory fields");
        }
    };

    const handleChangeDate = (e, name) => {
        if (e == null) {
            var date = ''
        } else {// eslint-disable-next-line
            var date = e.$d
        }
        let event = { target: { name: name, value: date } }
        if (name === "start_date") {
            values[name] = moment(date).format(dateFormat());
            setValues({ ...values });

            addLoader(true);
            var data = {
                placement_id: values.placement_id,
                start_date: moment(date).format(dateFormat())
            }
            TimesheetApi.timesheetExistsCheck(data).then((res) => {
                removeLoader();
                if (res.data.statusCode === 1003 && res.data.is_timesheet_exists === false) {
                    values.end_date = res.data.end_date;
                    setTimesheetEndDate(res.data.end_date)
                    setValues({ ...values })
                    getDatesRange(data.start_date, res.data.end_date, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                } else if (res.data.end_date == "") {
                    addErrorMsg("Placement has not yet started for the dates. Please recheck the uploaded file.");
                } else if (res.data.is_timesheet_exists === true) {
                    existData.id = res.data.id;
                    existData.status = res.data.status;
                    setExistData({ ...existData })
                    setOpenExists(true);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            if (values[name] != "") {
                // setValues({ ...values })
                if (capture && values.timesheet.length > 0) {
                    if (moment(date, dateFormat()).isAfter(moment(values.end_date, dateFormat()))) {
                        const finalTimesheetData = insertMissingDates(moment(values.start_date).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD"), formatNewDates(values.timesheet));
                        values['timesheet'] = finalTimesheetData;
                        values['weekly'] = handleTable(finalTimesheetData, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                    } else {
                        const filteredTimesheetData = values.timesheet.filter(item => moment(item.date, dateFormat()).isSameOrBefore(moment(date, dateFormat())));
                        values['timesheet'] = filteredTimesheetData;
                        values['weekly'] = handleTable(filteredTimesheetData, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                    }
                    values[name] = moment(date).format(dateFormat());
                    setValues({ ...values })
                } else {
                    values[name] = moment(date).format(dateFormat());
                    setValues({ ...values })
                }

            } else {
                values[name] = moment(date).format(dateFormat());
                setValues({ ...values })
            }

        }
        handleValidate(event);
    }

    /***********    API integration starts here       *************/

    const ocrDates = (dataArray) => {
        return dataArray.map(item => ({
            ...item,
            date: moment(item.date, dateFormat()).format("YYYY-MM-DD") // Changing date format
        }));
    }

    const _ = require('lodash');
    const handleCaptureOcrActivity = () => {
        var urlsArray = [];
        for (var i in values.documents) {
            urlsArray.push(values.documents[i].document_url)
        }
        var cheeckObject = {
            start_date: moment(values.start_date, dateFormat()).format("YYYY-MM-DD"),
            end_date: moment(values.end_date, dateFormat()).format("YYYY-MM-DD"),
            consultant: values.employee_name,
            timesheet: ocrDates(values.timesheet)
        }
        const areEqual = _.isEqual(cheeckObject, ocrData);
        var payLoad = {
            timesheet_urls: urlsArray,
            tenant_id: LocalStorage.getUserData().tenant_id,
            original_data: ocrData,
            changed_data: !areEqual,
            new_data: !areEqual ? cheeckObject : {}
        }
        TimesheetApi.storeOcrActivtyData(payLoad).then((res) => { })
    }

    const getTimeSheetDataWithId = (args) => {
        setLoading(true);
        addLoader(true);
        TimesheetApi.getTimesheetConsultant(args).then((res) => {
            removeLoader();
            setLoading(false);

            if (res.data.statusCode === 1003) {
                if (res.data.data.length > 0) {
                    if (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Draft") {
                        setEdit(res.data.data[0].is_editable);
                    } else {
                        setEdit(false);
                    }
                    setValues({
                        ...res.data.data[0],
                        documents_deleted_ids: [],
                        weekly: handleTable(res.data.data[0].timesheet, res.data.data[0].timesheet_start_day ? res.data.data[0].timesheet_start_day : 'Monday')
                    });

                }
                removeLoader();
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    };
    //  function for when getting data arranges tables 
    // eslint-disable-next-line
    const handleTable = (param, startDay) => {
        var dumArr = [];
        var finalDumArr = [];
        for (var i in param) {
            dumArr.push(param[i]);
            if (moment(param[i].date, dateFormat()).format("d") === handleRetunrDay(startDay)) {
                let finalObj = {
                    billable_hours: handleLoopingArray(dumArr, "bill"),
                    ot_hours: handleLoopingArray(dumArr, "ot"),
                    total_hours: handleLoopingArray(dumArr, "total"),
                    timesheet: dumArr,
                }
                finalDumArr.push(finalObj);
                dumArr = [];
            }
        }
        if (dumArr.length !== 0) {
            let finalOb = {
                billable_hours: handleLoopingArray(dumArr, "bill"),
                ot_hours: handleLoopingArray(dumArr, "ot"),
                total_hours: handleLoopingArray(dumArr, "total"),
                timesheet: dumArr,
            }
            finalDumArr.push(finalOb);
        }
        handleLoopingArray(dumArr);
        return finalDumArr;
    }

    const handleRetunrDay = (param) => {
        switch (param) {
            case 'Monday':
                return '0'
            case 'Tuesday':
                return '1'
            case 'Wednesday':
                return '2'
            case 'Thursday':
                return '3'
            case 'Friday':
                return '4'
            case 'Saturday':
                return '5'
            case 'Sunday':
                return '6'
            default:
                break;
        }
    }
    // function for assigning total hours based all calculation
    const handleLoopingArray = (param, param1) => {
        var dumpArray = [];
        if (param1 === "total") {
            for (var i in param) {
                dumpArray.push(param[i].total_hours);
            }
            return globalTimeCalculation(dumpArray);
        }
        else if (param1 === "ot") {
            for (var k in param) {
                dumpArray.push(param[k].ot_hours);
            }
            return globalTimeCalculation(dumpArray);
        }
        else {
            for (var j in param) {
                dumpArray.push(param[j].billable_hours);
            }
            return globalTimeCalculation(dumpArray);
        }
    }
    const storeTimeSheet = (data) => {
        TimesheetApi.timesheetStore(data).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setOpenSubmit(true);
                // addSuccessMsg("Timesheet added successfully");
            } else {
                addErrorMsg(res.data.message);
            }
        })
    };
    const updateTimeSheet = (data) => {
        TimesheetApi.timesheetUpdate(stateId.state.id, data).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setOpenSubmit(true);
                // addSuccessMsg("Timesheet updated successfully");
            } else {
                addErrorMsg(res.data.message);
            }
        })
    };
    const getEmployeesDropDown = () => {
        EmployeesApi.getPlacementEmployees().then((res) => {
            if (res.data.statusCode === 1003) {
                setTimeout(() => {
                    var empArray = [];
                    if (res.data.data.length > 0) {
                        empArray = [];
                        for (var i in res.data.data) {
                            var newObj = {
                                id: res.data.data[i].id,
                                value: `${res.data.data[i].value} (${res.data.data[i].reference_id})`
                            }
                            empArray.push(newObj);
                        }
                    }
                    setEmployees(empArray);
                }, 500)

            }
        })
    };
    const getEmployeePlacementInfo = (id) => {
        var stateData = values.end_date;
        addLoader(true);
        setPlacements([]);
        let data = {
            request_id: LocalStorage.uid(),
            employee_id: id,
            search: '',
            timesheet_status: []
        }
        PlacementApi.employeePlacementsConsultant(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setData(res.data.data);
                if (res.data.data.length > 1) {
                    let newArray = [];
                    for (var i in res.data.data) {
                        newArray.push({ id: res.data.data[i].id, value: `${res.data.data[i].reference_id} (${res.data.data[i].client_name})` });
                    }
                    setPlacements(newArray);
                    if (!capture) {
                        values['timesheet_start_date'] = '';
                    }
                    values.placement_id = '';
                    values['timesheet_cycle'] = '';
                    values['timesheet_start_day'] = '';
                    values['client_id'] = '';
                    values['client_name'] = '';
                    values['end_client_id'] = '';
                    values['end_client_name'] = '';
                    values['default_hours'] = "08:00";
                    values['is_timesheet_attachment_mandatory'] = false;
                    setValues({ ...values });

                } else if (res.data.data.length == 1) {
                    var resData = res.data.data[0];
                    setPlacements([{ id: resData.id, value: `${resData.reference_id} (${resData.client_name})` }])
                    values.placement_id = resData.id;
                    if (!capture) {
                        values['timesheet_start_date'] = resData.timesheet_start_date;
                    }
                    if (capture && values.timesheet != undefined && values.timesheet.length > 0) {
                        // values.weekly = handleTable(values.timesheet, resData.timesheet_start_day ? resData.timesheet_start_day : "Monday");
                        var payloadData = {
                            placement_id: resData.id,
                            start_date: values.start_date
                        }// eslint-disable-next-line
                        TimesheetApi.timesheetExistsCheck(payloadData).then((res) => {
                            if (res.data.statusCode === 1003 && res.data.is_timesheet_exists === false) {

                                if (capture) {
                                    if (moment(stateData, dateFormat()).isAfter(moment(res.data.end_date, dateFormat()))) {
                                        // const filteredTimesheetData = values.timesheet.filter(item => moment(item.date).format(dateFormat()) <= moment(res.data.end_date).format(dateFormat()));
                                        const filteredTimesheetData = values.timesheet.filter(item =>
                                            moment(item.date, dateFormat()).isSameOrBefore(moment(res.data.end_date, dateFormat()))
                                        );
                                        values['timesheet'] = filteredTimesheetData;
                                        values['weekly'] = handleTable(filteredTimesheetData, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                                        setValues({ ...values })
                                    } else {
                                        var finalTimesheets = insertMissingDates(moment(payloadData.start_date, dateFormat()).format("YYYY-MM-DD"), moment(res.data.end_date, dateFormat()).format("YYYY-MM-DD"), formatNewDates(values.timesheet));
                                        values['timesheet'] = finalTimesheets;
                                        values['weekly'] = handleTable(finalTimesheets, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                                        setValues({ ...values })
                                    }
                                } else {
                                    getDatesRange(payloadData.start_date, res.data.end_date, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                                }
                                values.end_date = res.data.end_date;
                                setTimesheetEndDate(res.data.end_date)
                                setValues({ ...values })
                            } else if (res.data.is_timesheet_exists === true) {
                                existData.id = res.data.id;
                                existData.status = res.data.status;
                                setExistData({ ...existData })
                                setOpenExists(true);
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })


                    }
                    values['timesheet_cycle'] = resData.timesheet_cycle;
                    values['timesheet_start_day'] = resData.timesheet_start_day;
                    values['client_id'] = resData.client_id;
                    values['client_name'] = resData.client_name;
                    values['end_client_id'] = resData.end_client_id;
                    values['end_client_name'] = resData.end_client_name;
                    values['default_hours'] = resData.default_hours ? resData.default_hours : "08:00";
                    values['is_timesheet_attachment_mandatory'] = resData.is_timesheet_attachment_mandatory ? resData.is_timesheet_attachment_mandatory : false;
                    setValues({ ...values });
                    error['placement_id'] = "";
                    setError({ ...error });
                }
                removeLoader();
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    };
    const handleDeleteTimesheet = () => {
        setDeleteLoad(true);
        TimesheetApi.timesheetDelete(values.id).then((res) => {
            setDeleteLoad(false);
            if (res.data.statusCode === 1003) {
                handleNavigateListing()
                addSuccessMsg("Timesheet deleted successfully");
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }
    const [rejectLoad, setRejectLoad] = useState(false);
    const [rejectSuccess, setRejectSuccess] = useState(false);
    const [rejectError, setRejectError] = useState({})
    const handleRejectTimesheet = () => {
        if (rejectComment == "") {
            rejectError['rejectComment'] = "This field is required";
            setRejectError({ ...rejectError });
            return false;
        }
        setRejectLoad(true);
        var data = {
            id: values.id,
            request_id: LocalStorage.uid(),
            placement_id: values.placement_id,
            status: "Rejected",
            reason_rejection: rejectComment,
            comments: values.comments,
            approval_count: values.approval_level
        }

        TimesheetApi.timesheetChangeStatus(values.id, data).then((res) => {
            setRejectLoad(false);
            if (res.data.statusCode === 1003) {
                setRejectSuccess(true);
                // addSuccessMsg("Timesheet rejected successfully");
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleApproved = () => {
        setLoading(true);
        var data = {
            id: values.id,
            request_id: LocalStorage.uid(),
            placement_id: values.placement_id,
            status: "Approved",
            comments: values.comments,
            approval_count: values.approval_level
        }
        TimesheetApi.timesheetChangeStatus(values.id, data).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setApprovedOpen(true);
                // addSuccessMsg("Timesheet approved successfully");
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    /***********    Ends here       *************/

    // func for based on client id filtering placement ID
    const placementSetting = (value) => {
        var stateData = values.end_date;
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === value) {
                const newValues = values;
                if (!capture) {
                    newValues['timesheet_start_date'] = data[i].timesheet_start_date;
                }
                newValues['placement_id'] = data[i].id;
                newValues['timesheet_cycle'] = data[i].timesheet_cycle;
                newValues['timesheet_start_day'] = data[i].timesheet_start_day;
                newValues['client_id'] = data[i].client_id;
                newValues['client_name'] = data[i].client_name;
                newValues['end_client_id'] = data[i].end_client_id;
                newValues['end_client_name'] = data[i].end_client_name;
                newValues['default_hours'] = data[i].default_hours ? data[i].default_hours : "08:00";
                newValues['is_timesheet_attachment_mandatory'] = data[i].is_timesheet_attachment_mandatory ? data[i].is_timesheet_attachment_mandatory : false;

                if (capture && values.timesheet != undefined && values.timesheet.length > 0) {

                    if (values.start_date == "") {
                        addErrorMsg("please select a start date");
                        return false
                    }

                    var payloadData = {
                        placement_id: values.placement_id,
                        start_date: values.start_date
                    }// eslint-disable-next-line
                    TimesheetApi.timesheetExistsCheck(payloadData).then((res) => {
                        if (res.data.statusCode === 1003 && res.data.is_timesheet_exists === false) {

                            if (capture) {
                                if (moment(stateData, dateFormat()).isAfter(moment(res.data.end_date, dateFormat()))) {
                                    // const filteredTimesheetData = values.timesheet.filter(item => moment(item.date).format(dateFormat()) <= moment(res.data.end_date).format(dateFormat()));
                                    const filteredTimesheetData = values.timesheet.filter(item =>
                                        moment(item.date, dateFormat()).isSameOrBefore(moment(res.data.end_date, dateFormat()))
                                    );
                                    newValues['timesheet'] = filteredTimesheetData;
                                    newValues['weekly'] = handleTable(filteredTimesheetData, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                                    setValues({ ...newValues })
                                } else {
                                    var finalTimesheets = insertMissingDates(moment(payloadData.start_date, dateFormat()).format("YYYY-MM-DD"), moment(res.data.end_date, dateFormat()).format("YYYY-MM-DD"), formatNewDates(newValues.timesheet));
                                    newValues['timesheet'] = finalTimesheets;
                                    newValues['weekly'] = handleTable(finalTimesheets, newValues.timesheet_start_day ? newValues.timesheet_start_day : 'Monday');
                                    setValues({ ...newValues })
                                }
                            } else {
                                getDatesRange(payloadData.start_date, res.data.end_date, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                            }
                            newValues.end_date = res.data.end_date;
                            setTimesheetEndDate(res.data.end_date);
                            setValues({ ...newValues })
                        } else if (res.data.is_timesheet_exists === true) {
                            existData.id = res.data.id;
                            existData.status = res.data.status;
                            setExistData({ ...existData })
                            setOpenExists(true);
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }
                setValues({ ...newValues });
            }
        }
    };

    const findMissingDates = (startDate, endDate, data) => {
        const missingDate = [];

        let currentDate = moment(startDate, "YYYY-MM-DD");
        const end = moment(endDate, "YYYY-MM-DD");
        // Loop through each date in the range
        while (currentDate.isSameOrBefore(end, 'day')) {
            const formattedDate = currentDate.format(dateFormat());
            // Check if the formattedDate exists in data
            const found = data.some(item => moment(item.date, "YYYY-MM-DD").format(dateFormat()) === formattedDate);
            // If not found in data, it's a missing date
            if (!found) {
                const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;
                const hours = isWeekend ? "00:00" : "08:00";

                missingDate.push({
                    "date": formattedDate,
                    "ot_hours": "00:00",
                    "total_hours": hours,
                    "billable_hours": hours
                });
            }
            // Move to the next day
            currentDate = currentDate.add(1, 'days');
        }

        return missingDate;
    };

    const formatDates = (dataArray) => {
        return dataArray.map(item => ({
            ...item,
            date: moment(item.date, "YYYY-MM-DD").format(dateFormat()) // Changing date format
        }));
    };
    const formatNewDates = (dataArray) => {
        return dataArray.map(item => ({
            ...item,
            date: moment(item.date, dateFormat()).format("YYYY-MM-DD") // Changing date format
        }));
    };

    // Function to insert missing dates into data array
    const insertMissingDates = (startDate, endDate, data) => {
        // Find missing dates
        const missingDates = findMissingDates(startDate, endDate, data);
        // Concatenate original data with missing dates
        const newData = [...data, ...missingDates];
        // Sort newData by date
        // newData.sort((a, b) => (moment(a.date).format(dateFormat()) > moment(b.date).format(dateFormat())) ? 1 : -1);
        var timesheetData = newData.sort((a, b) => {
            return moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD");
        });
        return formatDates(timesheetData);
    };

    const hadleSetEmpty = () => {
        // values.employee_id = '';
        values.placement_id = "";
        values.client_id = "";
        values.client_name = "";
        values.timesheet_start_day = "";
        values.timesheet_start_date = "";
        values.end_client_id = "";
        values.end_client_name = "";
        values.timesheet_cycle = "";
        values.start_date = "";
        values.end_date = "";
        values.comments = "";
        values.total_billable_hours = "";
        values.is_timesheet_attachment_mandatory = false;
        values.total_hours = "";
        values.total_ot_hours = "";
        values.timesheet = [];
        values.weekly = [];
        setValues({ ...values });
    }

    function levenshteinDistance(a, b) {
        const an = a.length;
        const bn = b.length;
        if (an === 0) return bn;
        if (bn === 0) return an;

        const matrix = Array(an + 1).fill(null).map(() => Array(bn + 1).fill(null));

        for (let i = 0; i <= an; i += 1) {
            matrix[i][0] = i;
        }

        for (let j = 0; j <= bn; j += 1) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= an; i += 1) {
            for (let j = 1; j <= bn; j += 1) {
                const indicator = a[i - 1] === b[j - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i][j - 1] + 1, // deletion
                    matrix[i - 1][j] + 1, // insertion
                    matrix[i - 1][j - 1] + indicator, // substitution
                );
            }
        }

        return matrix[an][bn];
    }

    function similarity(a, b) {
        const distance = levenshteinDistance(a, b);
        const maxLen = Math.max(a.length, b.length);
        return (maxLen - distance) / maxLen;
    }

    const handleCaptureOcr = () => {
        if (planDetails.ai?.available_credits == 0) {
            setOpenZero(true);
            // if (LocalStorage.getUserData().super_admin) {
            //     setOpenZero(true);
            // } else {
            //     navigate('/plan-purchase');
            // }
        } else {
            var newState = { ...values };
            if (newState.documents.length == 0) {
                setDocumentOpen(true);
                // addErrorMsg("upload timesheet document to capture the data");
                return false;
            }
            if (newState.documents.length > 0 && newState.documents[0].document_url != "") {
                setOpen(true)
                var urlsArray = [];
                for (var i in newState.documents) {
                    urlsArray.push(newState.documents[i].document_url)
                }
                TimesheetApi.getTimesheetOcrData(urlsArray).then((res) => {
                    setOpen(false)
                    if (res.data.statusCode === 1003 && res.data.data.data?.timesheet?.length > 0) {
                        if (!res.data.data.data?.consultant || res.data.data.data.consultant === "null") {
                            setEmployeeNotFound(true);
                            // addErrorMsg("We are unable to read the employee name. Please try again.");
                            return false;
                        }
                        // Safely compare newState.employee_name with res.data.data.consultant

                        const similarityScore = similarity(newState.employee_name, res.data.data.data?.consultant);
                        //condition for employee name must match 50% of ocr ocr read name
                        if (similarityScore < 0.5) {
                            setEmployeeOpen(true);
                            return false;
                        }

                        hadleSetEmpty();
                        setOcrData(res.data.data.data);
                        setCapture(true);
                        // const updatedData = res.data.data.data.timesheet.map(item => {
                        //     const { working_hours, ...rest } = item;
                        //     return {
                        //         ...rest,
                        //         billable_hours: working_hours
                        //     };
                        // });
                        setTimesheetEndDate(moment(res.data.data.data?.end_date, "YYYY-MM-DD").format(dateFormat()) || "");
                        var timesheetData = res.data.data.data?.timesheet.sort((a, b) => {
                            return moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD");
                        });
                        setOcrTimesheetData(timesheetData);
                        // Insert missing dates in the timesheet data
                        var finalTimesheetData = insertMissingDates(res.data.data.data?.start_date, res.data.data.data?.end_date, timesheetData);
                        // Update newState with formatted start_date, end_date, and the finalTimesheetData
                        newState['start_date'] = moment(res.data.data.data.start_date).format(dateFormat());
                        newState['end_date'] = moment(res.data.data.data.end_date).format(dateFormat());
                        newState['timesheet'] = finalTimesheetData;
                        newState['weekly'] = handleTable(finalTimesheetData, values.timesheet_start_day ? values.timesheet_start_day : 'Monday');
                        setValues(newState); // Use newState directly
                        addLoader(true);
                        setPlacements([]);
                        let data = {
                            request_id: LocalStorage.uid(),
                            employee_id: values.employee_id,
                            search: '',
                            timesheet_status: []
                        }
                        PlacementApi.employeePlacementsConsultant(data).then((result) => {
                            if (result.data.statusCode === 1003) {
                                setData(result.data.data);
                                if (result.data.data.length > 1) {
                                    let newArray = [];
                                    for (var i in result.data.data) {
                                        newArray.push({ id: result.data.data[i].id, value: `${result.data.data[i].reference_id} (${result.data.data[i].client_name})` });
                                    }
                                    setPlacements(newArray);
                                    newState['placement_id'] = '';
                                    newState['timesheet_cycle'] = '';
                                    newState['timesheet_start_day'] = '';
                                    newState['client_id'] = '';
                                    newState['client_name'] = '';
                                    newState['end_client_id'] = '';
                                    newState['end_client_name'] = '';
                                    newState['default_hours'] = "08:00";
                                    newState['is_timesheet_attachment_mandatory'] = false;
                                    setValues(newState);
                                } else if (result.data.data.length == 1) {
                                    var resData = result.data.data[0];
                                    setPlacements([{ id: resData.id, value: `${resData.reference_id} (${resData.client_name})` }])
                                    newState['placement_id'] = resData.id;
                                    newState['timesheet_cycle'] = resData.timesheet_cycle;
                                    newState['timesheet_start_day'] = resData.timesheet_start_day;
                                    newState['client_id'] = resData.client_id;
                                    newState['client_name'] = resData.client_name;
                                    newState['end_client_id'] = resData.end_client_id;
                                    newState['end_client_name'] = resData.end_client_name;
                                    newState['default_hours'] = resData.default_hours ? resData.default_hours : "08:00";
                                    newState['is_timesheet_attachment_mandatory'] = resData.is_timesheet_attachment_mandatory ? resData.is_timesheet_attachment_mandatory : false;
                                    setValues(newState);
                                    error['placement_id'] = "";
                                    setError({ ...error });

                                    if (newState.start_date != "") {
                                        var payloadData = {
                                            placement_id: resData.id ? resData.id : "",
                                            start_date: moment(res.data.data.data?.start_date, "YYYY-MM-DD").format(dateFormat())
                                        }
                                        TimesheetApi.timesheetExistsCheck(payloadData).then((response) => {
                                            if (response.data.statusCode === 1003 && response.data.is_timesheet_exists === false) {
                                                newState['end_date'] = "";
                                                newState['timesheet'] = [];
                                                newState['weekly'] = [];
                                                setValues(newState);
                                                newState['end_date'] = response.data.end_date;
                                                setTimesheetEndDate(response.data.end_date);
                                                var ed = moment(res.data.data.data?.end_date, "YYYY-MM-DD").format(dateFormat())
                                                if (moment(response.data.end_date, dateFormat()).isSameOrBefore(moment(ed, dateFormat()))) {
                                                    const filteredTimesheetData = finalTimesheetData.filter(item =>
                                                        moment(item.date, dateFormat()).isSameOrBefore(moment(response.data.end_date, dateFormat()))
                                                    );
                                                    newState['timesheet'] = filteredTimesheetData;
                                                    newState['weekly'] = handleTable(filteredTimesheetData, newState.timesheet_start_day ? newState.timesheet_start_day : 'Monday');
                                                } else {
                                                    var finalTimesheets = insertMissingDates(res.data.data.data?.start_date, moment(response.data.end_date, dateFormat()).format("YYYY-MM-DD"), formatNewDates(newState.timesheet));
                                                    newState['timesheet'] = finalTimesheets;
                                                    newState['weekly'] = handleTable(finalTimesheets, newState.timesheet_start_day ? newState.timesheet_start_day : 'Monday');
                                                }

                                                setValues(newState);
                                            } else if (response.data.end_date == "") {
                                                addErrorMsg("Placement has not yet started/ended for the dates. Please recheck the uploaded file.");
                                            } else if (response.data.is_timesheet_exists === true) {
                                                existData.id = response.data.id;
                                                existData.status = response.data.status;
                                                setExistData({ ...existData })
                                                setOpenExists(true);
                                            } else {
                                                // addErrorMsg(response.data.message);
                                            }
                                        })
                                    }


                                }
                                removeLoader();
                            } else {
                                removeLoader();
                            }
                        })
                        error.start_date = "";
                        error.placement_id = ""
                        setError({ ...error });
                        setOcrOpen(true);
                    } else {
                        setCapture(false);
                        addErrorMsg("Invalid response ,please try again")
                    }
                })
            }
        }
    }


    // this func for based startDay calling final function
    const getDatesRange = (startDate, endDate, startDay) => {
        const daysArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        switch (startDay) {
            case 'Monday':
                finalTable(startDate, endDate, daysArray, 0);
                break;
            case 'Tuesday':
                finalTable(startDate, endDate, daysArray, 1);
                break;
            case 'Wednesday':
                finalTable(startDate, endDate, daysArray, 2);
                break;
            case 'Thursday':
                finalTable(startDate, endDate, daysArray, 3);
                break;
            case 'Friday':
                finalTable(startDate, endDate, daysArray, 4);
                break;
            case 'Saturday':
                finalTable(startDate, endDate, daysArray, 5);
                break;
            case 'Sunday':
                finalTable(startDate, endDate, daysArray, 6);
                break;
            default:
                break;
        }
    };

    // this func based on startDate and endDate and startDay rendering table
    const finalTable = (startDate, endDate, daysArray, index) => {
        let newValues = values;
        newValues.weekly = [];
        setValues({ ...newValues }); // for every time changing the dates assign empty array
        var days = [];
        var tableHeader = [];
        var otHours = [];
        var billableHours = [];
        var totalHours = [];
        var timeSheet = [];

        // return true
        let dateSample = dayjs(startDate, dateFormat())
        let dateSample1 = dayjs(endDate, dateFormat())
        let diff = dateSample1.diff(dateSample, 'day')
        // let day = dateSample.add(4,'day')
        // return true

        for (var a = [], d = 0; d <= diff; d++) {
            let dateToadd = dateSample.add(d, 'day')
            days.push(dateToadd.get('day')); // storing days in array
            let dateVal = moment(dateToadd.$d).format(dateFormat())
            a.push(dateVal);
            tableHeader.push(daysArray[dateToadd.get('day')]);
            if (dateToadd.get('day') === 0 || dateToadd.get('day') === 6) {
                // condition for based date saturday and sunday storing empty time
                otHours.push("00:00");
                billableHours.push("00:00");
                totalHours.push("00:00");
            } else {
                otHours.push("00:00");
                billableHours.push(values.default_hours !== "" ? values.default_hours : "08:00");
                totalHours.push(values.default_hours !== "" ? values.default_hours : "08:00");
            }

            if (dateToadd.get('day') === index) {
                // condition for if day is reached time sheet start dat storing one table object in array
                for (var i = 0; i < a.length; i++) {
                    var newObject = {
                        days: days[i],
                        date: a[i],
                        ot_hours: otHours[i],
                        billable_hours: billableHours[i],
                        total_hours: totalHours[i],
                    };
                    timeSheet.push(newObject);
                }

                let newArray = values.weekly;
                let newObjec = {
                    billable_hours: globalTimeCalculation(billableHours),
                    ot_hours: globalTimeCalculation(otHours),
                    total_hours: globalTimeCalculation(totalHours),
                    timesheet: timeSheet,
                };
                newArray.push(newObjec);
                setValues({ ...values, weekly: newArray });

                /* clearing arrays for creating new Array*/
                days = [];
                a = [];
                tableHeader = [];
                otHours = [];
                timeSheet = [];
                totalHours = [];
                billableHours = [];
            }
        }
        if (tableHeader.length !== 0 && a.length !== 0) {
            for (var k = 0; k < a.length; k++) {
                var newObjec = {
                    days: days[k],
                    date: a[k],
                    ot_hours: otHours[k],
                    billable_hours: billableHours[k],
                    total_hours: totalHours[k],
                };
                timeSheet.push(newObjec);
            }

            let newArray = values.weekly;
            let newObject = {
                billable_hours: globalTimeCalculation(billableHours),
                ot_hours: globalTimeCalculation(otHours),
                total_hours: globalTimeCalculation(totalHours),
                timesheet: timeSheet,
            };
            newArray.push(newObject);
            setValues({ ...values, weekly: newArray });
        }

        timeSheetTotalCal(values);
    };
    //  func for assigning totals based on hours of each field total
    const timeSheetTotalCal = (values) => {
        if (values.weekly.length > 0) {
            var totalBillableHours = [];
            var totalOtHours = [];
            var totalHours = [];
            for (var i = 0; i < values.weekly.length; i++) {
                totalBillableHours.push(values.weekly[i].billable_hours);
                totalOtHours.push(values.weekly[i].ot_hours);
                totalHours.push(values.weekly[i].total_hours);
            }
            var newState = values;
            newState.total_billable_hours = globalTimeCalculation(totalBillableHours);
            newState.total_hours = globalTimeCalculation(totalHours);
            newState.total_ot_hours = globalTimeCalculation(totalOtHours);
            newState['timesheet'] = handleContact(newState.weekly);
            setValues({ ...newState });
        }
    };

    const handleContact = (param) => {
        var finalArray = [];
        if (param.length > 0) {
            for (var i in param) {
                for (var k in param[i].timesheet) {
                    finalArray.push(param[i].timesheet[k]);
                }
            }
            return finalArray;
        } else {
            return finalArray;
        }
    }

    // function for if any Billable time value is empty assign 0 values for that particular time key
    const assignEmptyBillableTime = () => {
        for (var tableIndex = 0; tableIndex < values.weekly.length; tableIndex++) {
            for (var a = 0; a < values.weekly[tableIndex].timesheet.length; a++) {
                if (values.weekly[tableIndex].timesheet[a].billable_hours.length === 0) {
                    values.weekly[tableIndex].timesheet[a].billable_hours = "00:00";
                } else if (values.weekly[tableIndex].timesheet[a].billable_hours.length === 1) {
                    values.weekly[tableIndex].timesheet[a].billable_hours = values.weekly[tableIndex].timesheet[a].billable_hours + "0:00";
                } else if (values.weekly[tableIndex].timesheet[a].billable_hours.length === 2) {
                    values.weekly[tableIndex].timesheet[a].billable_hours = values.weekly[tableIndex].timesheet[a].billable_hours + ":00";
                } else if (values.weekly[tableIndex].timesheet[a].billable_hours.length === 4) {
                    values.weekly[tableIndex].timesheet[a].billable_hours = values.weekly[tableIndex].timesheet[a].billable_hours + "0";
                } else {
                    values.weekly[tableIndex].timesheet[a].billable_hours = // eslint-disable-next-line
                        values.weekly[tableIndex].timesheet[a].billable_hours;
                }
                setValues(values);
            }
        }
    };
    // function for if any OT time value is empty assign 0 values for that particular time key
    const assignEmptyOtTime = () => {
        for (var tableIndex = 0; tableIndex < values.weekly.length; tableIndex++) {
            for (var a = 0; a < values.weekly[tableIndex].timesheet.length; a++) {
                if (values.weekly[tableIndex].timesheet[a].ot_hours.length === 0) {
                    values.weekly[tableIndex].timesheet[a].ot_hours = "00:00";
                } else if (values.weekly[tableIndex].timesheet[a].ot_hours.length === 1) {
                    values.weekly[tableIndex].timesheet[a].ot_hours = values.weekly[tableIndex].timesheet[a].ot_hours + "0:00";
                } else if (values.weekly[tableIndex].timesheet[a].ot_hours.length === 2) {
                    values.weekly[tableIndex].timesheet[a].ot_hours = values.weekly[tableIndex].timesheet[a].ot_hours + ":00";
                } else if (values.weekly[tableIndex].timesheet[a].ot_hours.length === 4) {
                    values.weekly[tableIndex].timesheet[a].ot_hours = values.weekly[tableIndex].timesheet[a].ot_hours + "0";
                } else {
                    values.weekly[tableIndex].timesheet[a].ot_hours = // eslint-disable-next-line
                        values.weekly[tableIndex].timesheet[a].ot_hours;
                }
                setValues(values);
            }
        }
    };
    // eslint-disable-next-line
    const assignValuesUpdate = (values) => {
        if (values.weekly.length > 0) {
            if (values.total_hours === '00:00') {
                var otHours = [];
                var billableHours = [];
                var totalHours = [];
                for (let i = 0; i < values.weekly.length; i++) {
                    for (let j = 0; j < values.weekly[i].timesheet.length; j++) {
                        otHours.push(values.weekly[i].timesheet[j].ot_hours);
                        billableHours.push(values.weekly[i].timesheet[j].billable_hours);
                        totalHours.push(values.weekly[i].timesheet[j].total_hours);

                        var day = moment(values.weekly[i].timesheet[j].date).format(dateFormat());
                        var newState = values;
                        newState.weekly[i].timesheet[j].days = day.get('day');
                        setValues({ ...newState });
                    }
                    const newObj = values;
                    newObj.weekly[i].billable_hours = globalTimeCalculation(billableHours);
                    newObj.weekly[i].ot_hours = globalTimeCalculation(otHours);
                    newObj.weekly[i].total_hours = globalTimeCalculation(totalHours);
                    setValues({ ...newObj });
                    otHours = [];
                    billableHours = [];
                    totalHours = [];
                }
                timeSheetTotalCal(values); // calling timeSheetTotalCal
            } else {
                for (let a = 0; a < values.weekly.length; a++) {
                    for (let b = 0; b < values.weekly[a].timesheet.length; b++) {
                        var d = moment(values.weekly[a].timesheet[b].date).format(dateFormat());
                        var newDaysState = values;
                        newDaysState.weekly[a].timesheet[b].days = d.get('day');
                        setValues({ ...newDaysState });
                    }
                }
            }
        }
    };

    /***********  Time calculation functions starts here   **********/

    /* total time calculation fun for array of times*/
    //   let arr = ['90:15', '60:09', '50:20', '09:20', '40:30'];
    // it will calculate hhh:mm or hh:mm
    const globalTimeCalculation = (arr) => {
        var start = "00:00";
        for (var i = 0; i < arr.length; i++) {
            start = sumTime(start, arr[i]);
        }
        return start;
    }

    const diffTime = (start, end) => {
        var s = start.split(":"),
            sMin = +s[1] + s[0] * 60,
            e = end.split(":"),
            eMin = +e[1] + e[0] * 60,
            diff = eMin + sMin;
        var h = Math.floor(diff / 60),
            m = diff % 60;
        return "" + pad(h) + ":" + pad(m);
    };
    const pad = (num) => {
        return ("0" + num).slice(-2);
    };

    function sumTime(time1, time2) {
        // Split time into hours and minutes
        var [hours1, minutes1] = time1.split(':');
        var [hours2, minutes2] = time2.split(':');

        // Convert time to minutes
        var totalMinutes = parseInt(hours1) * 60 + parseInt(minutes1) + parseInt(hours2) * 60 + parseInt(minutes2);

        // Convert minutes back to HH:MM format
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        // Add leading zeros if necessary
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return hours + ':' + minutes;
    }

    /***********  Ends Here   **********/

    // handle change validation
    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "employee_id":
                error.employee_id = validate_emptyField(input.value);
                break;
            case "placement_id":
                error.placement_id = validate_emptyField(input.value);
                break;
            case "start_date":
                error.start_date = validate_emptyField(input.value);
                break;
            case "end_date":
                error.end_date = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    };
    // final submission validation/
    const formValidations = () => {
        const { employee_id, placement_id, start_date, documents, end_date } = values;
        let errors = {};
        errors.employee_id = validate_emptyField(employee_id);
        errors.placement_id = validate_emptyField(placement_id);
        errors.start_date = validate_emptyField(start_date);
        errors.end_date = validate_emptyField(end_date);
        errors.documents = values.is_timesheet_attachment_mandatory === true && documents.length === 0 ? "This field is required" : '';
        return errors;
    };

    /**
     * func for if user change employee filed
     * clearing all remaining fields
     * */
    // eslint-disable-next-line
    const handleClear = () => {
        setError({});
        setPlacements([]);
        values.employee_id = employee;
        values.placement_id = "";
        values.client_id = "";
        values.client_name = "";
        values.timesheet_start_day = "";
        values.timesheet_start_date = "";
        values.end_client_id = "";
        values.end_client_name = "";
        values.timesheet_cycle = "";
        values.start_date = "";
        values.end_date = "";
        values.comments = "";
        values.total_billable_hours = "";
        values.is_timesheet_attachment_mandatory = false;
        values.total_hours = "";
        values.total_ot_hours = "";
        values.weekly = [];
        values.documents = [];
        if (employee != "") {
            getEmployeePlacementInfo(employee); // calling employee placement details function
        }
        if (employee == "") {
            error['employee_id'] = "This filed is required";
            setError({ ...error });
        }
        setAlertOpen(false);
    };

    const handleClearPlacement = () => {
        if (values.employee_id !== "" && placementId == "") {
            values.placement_id = "";
            error['placement_id'] = "This filed is required";
            setError({ ...error });
        }
        values.client_id = "";
        values.client_name = "";
        values.timesheet_start_day = "";
        values.timesheet_start_date = "";
        values.end_client_id = "";
        values.end_client_name = "";
        values.timesheet_cycle = "";
        values.start_date = "";
        values.end_date = "";
        values.comments = "";
        values.total_billable_hours = "";
        values.is_timesheet_attachment_mandatory = false;
        values.total_hours = "";
        values.total_ot_hours = "";
        values.weekly = [];
        values.documents = [];
        // setValues({...values})
        if (placementId !== "") {
            placementSetting(placementId);
        }

        setAlertOpen(false);
    }

    const multiDocsUpload = (files) => {
        for (const key in files) {
            if (files.length > 5) {
                addErrorMsg("Upload 5 Documents Only")
            }
            if (values.documents.length < 5) {
                if (files[key].type == "image/JPEG" || files[key].type == "image/png" || files[key].type == "image/jpg" || files[key].type == "image/jpeg" || files[key].type == "image/PNG") {
                    var fileSize = files[key].size / (1024 * 1024);
                    if (fileSize.toFixed(2) <= 25) {
                        addLoader(true);
                        const formData = new FormData();
                        formData.append('files', files[key]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("timesheet-document", formData, LocalStorage.getAccessToken()).then((response) => {
                            removeLoader();
                            if (response.data.statusCode == 1003) {
                                error['documents'] = "";
                                setError({ ...error });
                                var obj = {
                                    document_name: response.data.data.document_name,
                                    new_document_id: response.data.data.id,
                                    document_url: response.data.data.document_url,
                                    slug: ""
                                }
                                if (values.documents.length < 5) {
                                    var newDocuments = values.documents;
                                    newDocuments.push(obj);
                                    setValues({ ...values, documents: newDocuments });
                                } else {
                                    return false;
                                }
                            }
                            else {
                                addErrorMsg(response.data.message);
                            }
                        })
                    } else {
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                } else {
                    addErrorMsg(`Upload document Format JPEG/PNG`);
                }
            } else {
                addErrorMsg("Upload 5 Documents Only")
            }
        }
    }

    const otherDocumentRemove = (index) => {
        setDeleteOpen(true);
        setDeleteIndex(index);
    }

    const deleteDoc = (index) => {
        if (values.id != undefined && values.id !== "" && values.documents[index].id !== "") {
            values['documents_deleted_ids'].push(values.documents[index].id);
        }
        values.documents.splice(index, 1);
        setValues({ ...values });
        setDeleteIndex(null);
        setDeleteOpen(false);
        if (values.is_timesheet_attachment_mandatory === true && values.documents.length == 0) {
            error['documents'] = "This filed is required";
            setError({ ...error });
        }
        if (values.documents.length == 0) {
            setCapture(false);
        }
    }

    // based date return added one month 
    // eslint-disable-next-line
    const getOneMonthEndDate = (date) => {
        // New Second cycle end date
        var temp2 = moment(date, dateFormat());
        const year = Number(temp2.format('YYYY'));
        const month = Number(temp2.format('M'));
        const day = Number(temp2.format('D'));

        let newDate;
        let newMonth;
        let newYear;
        let leapYear = (year % 100 == 0) ? (year % 400 == 0) : (year % 4 == 0);
        if (!leapYear) {
            if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
                if (month == 1) {
                    if (day == 1) {
                        newDate = 31;
                        newMonth = month;
                        newYear = year;
                    } else if (day <= 29) {
                        newDate = day - 1;
                        newMonth = month + 1;
                        newYear = year;
                    } else {
                        newDate = 27;
                        newMonth = month + 1;
                        newYear = year;
                    }
                } else {
                    if (day == 1) {
                        newDate = 31;
                        newMonth = month;
                        newYear = year;
                    } else if (day <= 30) {
                        newDate = day - 1;
                        newMonth = month + 1;
                        newYear = year;
                        if (month == 12) {
                            newMonth = 1;
                            newYear = year + 1;
                        }
                    } else {
                        if (month + 1 == 8) {
                            newDate = 30
                        } else {
                            newDate = 29
                        }
                        if (month == 12) {
                            newMonth = 1;
                            newYear = year + 1;
                            newDate = 30
                        } else {
                            newMonth = month + 1;
                            newYear = year;
                        }
                    }
                }
            } else {
                if (month == 2) {
                    if (day == 1) {
                        newDate = 28;
                        newMonth = month;
                        newYear = year;
                    } else if (day < 28) {
                        newDate = day - 1;
                        newMonth = month + 1;
                        newYear = year;
                    } else {
                        newDate = 30;
                        newMonth = month + 1;
                        newYear = year;
                    }
                } else {
                    if (day == 1) {
                        newDate = 30;
                        newMonth = month;
                        newYear = year;
                    } else if (day < 30) {
                        newMonth = month + 1;
                        newYear = year;
                        newDate = day - 1;
                    } else {
                        newDate = 30;
                        newMonth = month + 1;
                        newYear = year;
                    }
                }
            }
        } else {
            if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
                if (month == 1) {
                    if (day == 1) {
                        newDate = 31;
                        newMonth = month;
                        newYear = year;
                    } else if (day <= 29) {
                        newDate = day - 1;
                        newMonth = month + 1;
                        newYear = year;
                    } else {
                        newDate = 28;
                        newMonth = month + 1;
                        newYear = year;
                    }
                } else {
                    if (day == 1) {
                        newDate = 31;
                        newMonth = month;
                        newYear = year;
                    } else if (day <= 30) {
                        newDate = day - 1;
                        newMonth = month + 1;
                        newYear = year;
                        if (month == 12) {
                            newMonth = 1;
                            newYear = year + 1;
                        }
                    } else {
                        if (month + 1 == 8) {
                            newDate = 30
                        } else {
                            newDate = 29
                        }
                        if (month == 12) {
                            newMonth = 1;
                            newYear = year + 1;
                            newDate = 30
                        } else {
                            newMonth = month + 1;
                            newYear = year;
                        }
                    }
                }
            } else {
                if (month == 2) {
                    if (day == 1) {
                        newDate = 29;
                        newMonth = month;
                        newYear = year;
                    } else if (day < 29) {
                        newDate = day - 1;
                        newMonth = month + 1;
                        newYear = year;
                    } else {
                        newDate = 30;
                        newMonth = month + 1;
                        newYear = year;
                    }
                } else {
                    if (day == 1) {
                        newDate = 30;
                        newMonth = month;
                        newYear = year;
                    } else if (day < 30) {
                        newMonth = month + 1;
                        newYear = year;
                        newDate = day - 1;
                    } else {
                        newDate = 30;
                        newMonth = month + 1;
                        newYear = year;
                    }
                }
            }
        }
        const modifiedDate = moment([newYear, (newMonth - 1), newDate]).format(dateFormat());
        return modifiedDate;
    };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setOpen(false);
    //     }, 2000); // 3000 milliseconds = 3 seconds

    //     return () => clearTimeout(timer);
    // }, [open])

    const handleSetEdit = () => {
        setEdit(!edit);
    }
    const [rejectOpen, setRejectOpen] = useState(false);
    const [rejectComment, setRejectComment] = useState("");
    const handleRejectOpen = () => {
        setRejectComment("");
        setRejectOpen(true);
    }

    const handleRejectChange = (e) => {
        setRejectComment(e.target.value);
        handleValidateReject(e)
    }

    const handleValidateReject = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "rejectComment":
                rejectError.rejectComment = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setRejectError({ ...rejectError });
    }

    const handleNavigateListing = () => {
        navigate("/timesheet", { state: { status: stateId.state !== null ? stateId.state.status : "Draft" } })
    }

    return (
        <Box sx={{ width: "100%" }}>

            <Box p={1} sx={{ marginLeft: "76px" }}>
                <Box mx={3} display='flex' justifyContent='space-between'>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography onClick={() => { navigate("/timesheet") }} className={classes.breadcrumbsLink}>Timesheet</Typography>
                        <Typography className={classes.breadcrumbsName}>
                            {stateId.state !== null && stateId.state.id !== "" ? (
                                stateId.state.status === "Draft" ? "Drafted Timesheet" :
                                    stateId.state.status === "Pending Approval" ? "Pending Timesheet" :
                                        stateId.state.status === "Approved" ? "Approved Timesheet" :
                                            stateId.state.status === "Rejected" ? "Rejected Timesheet" :
                                                "Add Timesheet"
                            ) : "Add Timesheet"}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box m={3}>
                    {
                        (stateId.state !== null && stateId.state.id !== "") ?
                            <Box my={2} p={2} className={classes.employeViewContainer}>
                                <Box className={classes.employeViewBox}>
                                    <Grid container spacing={0} alignItems={"center"}>
                                        <Grid item lg={4} md={4} sm={12} xs={12} >
                                            <Box px={1} className={classes.flexAlineCenter} >
                                                <Box>
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        // src={AvatarSvg}
                                                        src={values.profile_picture_url}
                                                        sx={{ width: '80px', height: '80px', }}
                                                    />
                                                </Box>
                                                <Box mx={2}>
                                                    <Text mediumBoldBlack noWrap>
                                                        {values.employee_name === "" ? "--" :
                                                            capitalizeAndAddSpace(values.employee_name).length > 16 ?
                                                                <HtmlTooltip title={capitalizeAndAddSpace(values.employee_name)} placement="right" arrow>
                                                                    {capitalizeAndAddSpace(values.employee_name).slice(0, 16) + (capitalizeAndAddSpace(values.employee_name).length > 16 ? "..." : "")}
                                                                </HtmlTooltip>
                                                                :
                                                                capitalizeAndAddSpace(values.employee_name)
                                                        }
                                                        <img src={values.employee_e_verified !== 1 ? disableVerified : Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                    </Text>

                                                    <Text className={classes.labales} greyLabel noWrap style={{ margin: '4px 0' }}>  {values.placement_reference_id ? values.placement_reference_id : "--"} &#128900; {values.timesheet_reference_id ? values.timesheet_reference_id : "--"}</Text>
                                                </Box>
                                            </Box>

                                        </Grid>
                                        <Grid item lg={3} md={3} sm={12} xs={12} >
                                            <Text className={classes.labales} greyLabel noWrap>Approval Status</Text>
                                            {
                                                stateId.state.status === "Draft" ?
                                                    <Box className={classes.statusBox} mt="5px">
                                                        <Text mediumBoldWhite>Drafted Timesheet</Text>
                                                    </Box> :
                                                    stateId.state.status === "Pending Approval" ?
                                                        <Box className={classes.statusBoxP} mt="5px">
                                                            <Text mediumBoldWhite>Pending for approval</Text>
                                                        </Box> :
                                                        stateId.state.status === "Approved" ?
                                                            <Box className={classes.statusBoxA} mt="5px">
                                                                <Text mediumBoldWhite>Approved</Text>
                                                            </Box> : stateId.state.status === "Rejected" ?
                                                                <Box className={classes.statusBoxR} mt="5px">
                                                                    <Text mediumBoldWhite>Rejected</Text>
                                                                </Box> : ""
                                            }


                                        </Grid>
                                        <Grid item lg={3} md={3} sm={12} xs={12} >
                                            {
                                                stateId.state.status === "Draft" ?
                                                    <>
                                                        <Text className={classes.labales} mediumLabel noWrap>Timesheet Cycle</Text>
                                                        <Text mediumViewAmt mt="5px">{values.timesheet_cycle ? values.timesheet_cycle : "--"}</Text>
                                                    </> :
                                                    <>
                                                        <Text className={classes.labales} mediumLabel noWrap>Total Logged  Hours</Text>
                                                        <Text mediumViewAmt mt="5px">{values.total_hours ? values.total_hours : "--"}</Text>
                                                    </>
                                            }

                                        </Grid>
                                        <Grid item lg={3} md={2} sm={12} xs={12} >
                                            {
                                                stateId.state.status === "Draft" ?
                                                    <>
                                                        <Text className={classes.labales} mediumLabel noWrap>Drafted On</Text>
                                                        <Text mediumViewAmt mt="5px">{values.drafted_on ? values.drafted_on : "--"}</Text>
                                                    </> :
                                                    <>
                                                        <Text className={classes.labales} mediumLabel noWrap>Submitted Date</Text>
                                                        <Text mediumViewAmt mt="5px">{values.submitted_on ? moment(values.submitted_on).format('DD-MMM-YYYY') : "--"}</Text>
                                                    </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            : ""
                    }

                    {
                        (stateId.state !== null && stateId.state.id !== "") ?
                            <Box p={4} sx={{
                                border: "1px solid #EAECF0", borderRadius: '12px', height: "123px",
                                "@media (min-width:1200px) and (max-width:1400px)": {
                                    height: "160px !important",
                                },
                            }}>
                                <Grid container spacing={1} columnGap={4}>
                                    <Grid item lg={3.5} md={3.5} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'client_name',
                                                value: values.client_name,
                                                type: 'text'
                                            }}
                                            disabled={true}
                                            // handleChange={handleChange}
                                            clientInput
                                            labelText={'Client Name'}
                                        />
                                    </Grid>
                                    <Grid item lg={3.5} md={4} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'end_client_name',
                                                value: values.end_client_name,
                                                type: 'text',
                                                disabled: true
                                            }}
                                            disabled={true}
                                            // handleChange={handleChange}
                                            clientInput
                                            labelText={'End Client (Optional)'}
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <Datepicker
                                            labelText={"Start Date"}
                                            name={"start_date"}
                                            value={values.start_date}
                                            // minDate={moment(values.timesheet_start_date).format(dateFormat())}
                                            // onChange={(e) => handleChangeDate(e, "start_date")}
                                            error={error.start_date}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <Datepicker
                                            labelText={"End Date"}
                                            name={"end_date"}
                                            value={values.end_date}
                                            minDate={values.start_date}
                                            // onChange={(e) => handleChangeDate(e, "end_date")}
                                            disabled={true}
                                        />
                                        {/* <Text red> {error.end_date ? error.end_date : ""}</Text> */}
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            <Box p={4} sx={{ border: "1px solid #EAECF0", borderRadius: '12px' }}>
                                <Text profileTitle >New Timesheet</Text>
                                <Grid container mt={3} spacing={0} columnGap={4}>
                                    <Grid item lg={3.5} md={4} sm={12} xs={12}>
                                        {/* <CustomSelect
                                    name='placement_id'
                                    value={values.placement_id}
                                    onChange={handleChange}
                                    commonSelect
                                    label={'Select Placement ID'}
                                    options={placements}

                                // disabled={state.send_revocation.disabled}
                                /> */}
                                        <Select
                                            name='placement_id'
                                            value={values.placement_id}
                                            onChange={handleChange}
                                            options={placements}
                                            label={'Select Placement ID'}
                                            helperText={error.placement_id ? <Text errorText> {error.placement_id ? error.placement_id : ""}</Text> : ""}
                                        />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Datepicker
                                            labelText={"Start Date"}
                                            name={"start_date"}
                                            value={values.start_date}
                                            // minDate={moment(values.timesheet_start_date).format(dateFormat())}
                                            onChange={(e) => handleChangeDate(e, "start_date")}
                                            error={error.start_date}
                                            disabled={values.placement_id == ""}
                                        />
                                        <Text red> {error.start_date ? error.start_date : ""}</Text>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Datepicker
                                            labelText={"End Date"}
                                            name={"end_date"}
                                            value={values.end_date}
                                            maxDate={moment(timesheetEndDate).format(dateFormat())}
                                            minDate={values.start_date}
                                            onChange={(e) => handleChangeDate(e, "end_date")}
                                            disabled={true}
                                            error={error.end_date}
                                        />
                                        {/* <Text red> {error.end_date ? error.end_date : ""}</Text> */}
                                    </Grid>

                                    <Grid item lg={3.5} md={3.5} sm={12} xs={12} pt={3}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'client_name',
                                                value: values.client_name,
                                                type: 'text'
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'Client Name'}
                                        />
                                    </Grid>
                                    <Grid item lg={3.5} md={4} sm={12} xs={12} pt={3}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'end_client_name',
                                                value: values.end_client_name,
                                                type: 'text'
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'End Client (Optional)'}
                                        />
                                    </Grid>
                                    <Grid item lg={4.3} md={4} sm={12} xs={12} pt={3}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'timesheet_cycle',
                                                value: values.timesheet_cycle,
                                                type: 'text'
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'Timesheet Cycle'}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                    }

                    <Box mt={2} p={4} sx={{ border: "1px solid #EAECF0", borderRadius: '12px', height: "50vh", overflow: "auto" }}>

                        <Grid item lg={12} md={12}>
                            {
                                edit ?
                                    <Box className={classes.dropzoneMainBox}>
                                        <Box className={classes.dropBox}>
                                            <Dropzone onDrop={acceptedFiles => { multiDocsUpload(acceptedFiles) }}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section className={classes.dropZone}>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {
                                                                isDragActive ?
                                                                    <Grid container spacing={1} justifyContent='center'>
                                                                        <Text normal my={10} className={classes.dropzoneText}>drop the files here</Text>
                                                                    </Grid>
                                                                    :
                                                                    <Grid container spacing={1} textAlign='center'>
                                                                        <Grid item lg={12} md={12} textAlign='center'>
                                                                            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                                <Text largeBlack>Drag & Drop Documents</Text>
                                                                                <Text smallGrey>or</Text>

                                                                                <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>

                                                                                <Text largeBlack>To Upload</Text>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item lg={12} md={12} mt={1}>
                                                                            <Text smallGreyFont>Document Size 25MB, Format - JPEG,PNG</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                            }
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </Box>
                                    </Box>
                                    : ""}
                        </Grid>

                        <Grid item lg={12} md={12} pt={2}>
                            {
                                values.documents.length > 0 ?
                                    <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", background: !edit ? "#EEEEEE" : "#ffffff" }}>
                                        <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#0C75EB !important" }}>Uploaded Documents</Text>
                                        <Grid item container lg={12} md={12} columnGap={1.5}>
                                            {
                                                values.documents.map((item, index) => (
                                                    <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                                        <Text sx={{ color: "#0C75EB !important", font: "12px Quicksand !important", fontWeight: `${600} !important` }}>
                                                            <a href={item.document_url} style={{ color: "#0C75EB" }} target="_blank" rel="noreferrer">
                                                                {item.document_name.length > 16 ?
                                                                    <BlackToolTip arrow placement='top' title={item.document_name}>
                                                                        {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                                                    </BlackToolTip>
                                                                    :
                                                                    <>
                                                                        {item.document_name}
                                                                    </>}
                                                            </a>
                                                        </Text>
                                                        {edit ? <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { otherDocumentRemove(index) }} /> : ""}
                                                    </Box>
                                                ))
                                            }
                                        </Grid>
                                    </Box> :
                                    <Box sx={{ border: error.documents ? "1px solid #ec4444" : "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px", background: !edit ? "#EEEEEE" : "#ffffff" }}>
                                        <Text smallLabel sx={{ font: "14px Quicksand !important", color: error.documents ? "#ec4444 !important" : "#737373 !important" }}>Uploaded Documents</Text>
                                        <Grid item container lg={12} columnGap={1.5}>
                                        </Grid>
                                    </Box>
                            }
                            <Text red> {error.documents ? error.documents : ""}</Text>
                        </Grid>
                        {edit &&
                            <Grid container>
                                <Grid container columnGap={0.6} item lg={6} md={6} sm={12} xs={12} mt={2} justifyContent={"start"} alignItems={"center"}>
                                    {capture ?
                                        <>
                                            <CircleIcon sx={{ color: "#FAC94B", fontSize: "14px", marginTop: "2px" }} />
                                            <Text BlackExtraDark>AI Fetched Hours</Text>
                                            <CircleIcon sx={{ color: "#C6C5C2", fontSize: "14px", marginTop: "2px", marginLeft: "8px" }} />
                                            <Text BlackExtraDark>AI Unfetched Hours</Text>
                                        </> : ""
                                    }
                                </Grid>
                                <Grid container item lg={6} md={6} sm={12} xs={12} mt={2} justifyContent={"end"} alignItems={"center"}>
                                    {/* <HtmlTooltip
                                        placement="bottom"
                                        arrow
                                        title={
                                            <React.Fragment>
                                                <Box>
                                                    <Typography className={classes.profileTooltipText}>
                                                        Clicking on this will capture <br />all the data from the <br />uploaded timesheet and <br />translated to table below <br />using AI OCR
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={infoCircle} alt="infoCircle" height={"18px"} style={{ marginRight: "8px", marginBottom: "-4px", cursor: "pointer" }} />
                                    </HtmlTooltip>
                                    <Button onClick={() => { handleCaptureOcr() }} blueOutlineBtn sx={{ width: "195px !important" }}>Capture using AI</Button> */}
                                    <Button CreditsButton onClick={() => { handleCaptureOcr() }} /*onClick={() => { setOpen(true) }}*/ /*endIcon={<Box className={classes.aiButtonBeta}>(Beta)</Box>}*/>
                                        <Box display='flex' flexDirection='row' alignItems='center' gap={planDetails.ai?.available_credits == 0 ? 0 : 2}>
                                            {
                                                captureLoader ? '' :
                                                    <>
                                                        {
                                                            planDetails.ai?.available_credits == 0 ?
                                                                <Box textAlign='center'>
                                                                    <Text largeWhite20>0 Credits Left</Text>
                                                                </Box> :
                                                                <Box textAlign='start'>
                                                                    <Text largeWhite20>{planDetails.ai?.available_credits}&nbsp;<span style={{ font: '12px Quicksand', fontWeight: '400' }}>Left</span></Text>
                                                                    <Text mediumWhite400>AI Timesheets</Text>
                                                                </Box>
                                                        }
                                                        {
                                                            planDetails.ai?.available_credits == 0 ?
                                                                <img src={rightArrow} alt="arrow" style={{ padding: '2px 0px 0px 2px' }} /> :
                                                                <Box display='flex' flexDirection='row' alignItems='center' textAlign='end'>
                                                                    <Text mediumWhite400 sx={{
                                                                        color: '#F5F5F5',
                                                                        '&:hover': {
                                                                            opacity: 1,
                                                                        }
                                                                    }}>Capture</Text>
                                                                    <img src={rightArrow} alt="arrow" style={{ padding: '2px 0px 0px 2px' }} />
                                                                </Box>
                                                        }
                                                    </>
                                            }
                                        </Box>
                                    </Button>
                                </Grid>
                            </Grid>
                        }

                        {values.timesheet != undefined && values.weekly != undefined && values.timesheet.length > 0 && values.weekly.length > 0 ? (
                            <Box>
                                {values.weekly.map((value, index) => (
                                    <TimeSheetTable
                                        data={value}
                                        index={index}
                                        onChange={tableHandleChange}
                                        status={stateId.state !== null && stateId.state.status}
                                        otHoursHandleChange={otHoursHandleChange}
                                        isEdit={!edit}
                                        ocrTimesheetData={ocrTimesheetData}
                                        capture={capture}
                                        formStatus={stateId.state !== null && stateId.state.id !== "" && stateId.state.status ? "view" : "add"}
                                    />
                                ))}
                            </Box>
                        ) : null}
                        {
                            stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Rejected" &&
                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'reason_rejection',
                                        value: values.reason_rejection ? values.reason_rejection : "",
                                        inputProps: { maxLength: 150 }
                                    }}
                                    disabled={true}
                                    clientInput
                                    labelText={'Reason for Rejection'}
                                // handleChange={handleChange}
                                />
                            </Grid>
                        }
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Input
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'comments',
                                    value: values.comments,
                                    inputProps: { maxLength: 150 }
                                }}
                                disabled={!edit}
                                clientInput
                                labelText={'Comments'}
                                handleChange={handleChange}
                            />
                        </Grid>
                        {/* Buttons */}
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12} textAlign={"start"} pt={3}>
                                {
                                    (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Draft") ?
                                        <></>
                                        : (stateId.state?.id !== "" && ["Rejected"].includes(stateId.state?.status)) ?
                                            <>{values.is_editable && !edit ? <Button cancelOutlineBlue className={classes.btnStyles} onClick={() => { handleSetEdit() }}>Edit</Button> : ""}</>
                                            : ""
                                }
                            </Grid>
                            <Grid container item lg={6} md={6} sm={6} xs={12} justifyContent={"end"} pt={3}>
                                {
                                    (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Approved") ?
                                        "" :
                                        (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Pending Approval") ?
                                            <>{values.is_approver && !edit ? <Button cancelOutlineBlue className={classes.btnStyles} sx={{ marginRight: "15px !important" }} onClick={() => { handleRejectOpen() }}>Reject</Button> : ""}</>
                                            :
                                            <>{values.is_submit ? <Button cancelOutlineBlue className={classes.btnStyles} sx={{ marginRight: "15px !important" }} onClick={() => { setNavigateOpen(true) }}>Cancel</Button> : ""}</>
                                }
                                {
                                    (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Draft") ?
                                        <>{values.is_submit ? <LoadingButton saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => handleSubmit()}>Submit</LoadingButton> : ""}</>
                                        :
                                        (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Pending Approval") ?
                                            <>
                                                {values.is_approver ?
                                                    <>{
                                                        edit ?
                                                            <LoadingButton saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => handleSubmit()}>Resubmit</LoadingButton>
                                                            :
                                                            <LoadingButton saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => handleApproved()}>Approve</LoadingButton>
                                                    }
                                                    </>
                                                    :
                                                    <Button saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => { handleNavigateListing() }}>Done</Button>
                                                }
                                            </>
                                            :
                                            (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Approved") ?
                                                <>
                                                    {edit && values.is_submit ?
                                                        <LoadingButton saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => handleSubmit()}>Resubmit</LoadingButton> :
                                                        <Button saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => { handleNavigateListing() }}>Done</Button>
                                                    }
                                                </>
                                                : (stateId.state !== null && stateId.state.id !== "" && stateId.state.status === "Rejected") ?
                                                    <>
                                                        {
                                                            values.is_submit ?
                                                                <>
                                                                    {edit ?
                                                                        <LoadingButton saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => handleSubmit()}>Submit</LoadingButton>
                                                                        :
                                                                        <Button saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => { handleSetEdit() }}>Resubmit</Button>
                                                                    }
                                                                </>
                                                                :
                                                                <Button saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => { handleNavigateListing() }}>Done</Button>
                                                        }
                                                    </>
                                                    : <LoadingButton saveNcontinue className={classes.btnStyles} loading={loading} onClick={() => handleSubmit()}>Submit</LoadingButton>
                                }

                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Box>
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(deleteIndex) }} />}
            {deleteTimesheet && <ReusableDelete loading={deleteLoad} timesheet={true} content={"Do you want to delete this timesheet?"} open={deleteTimesheet} setOpen={setDeleteTimesheet} onClick={() => { handleDeleteTimesheet() }} />}
            {alertOpen && <ReusableDelete change={true} content={"Are you sure you want to make the changes? All the details entered will be lost."} open={alertOpen} setOpen={setAlertOpen} onClick={() => { placementClear ? handleClearPlacement() : handleClear() }} />}
            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => setOpen(false)}
            >
                <DialogContent sx={{ padding: "30px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} p={2}>
                        <Grid item md={12} sm={12} xs={12} textAlign={"center"}>
                            <img src={newLoader} alt="newLoader" width={'100px'} height={'100px'} />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3}>
                            <Text largeBlack700>Harnessing the data winds... Blowing in your direction!</Text>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={openExists}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "30px", textAlign: "center" }}>
                    <Grid container>
                        <Grid item md={12} sm={12}>
                            <img src={deleteAlert} alt='deleteAlert' />
                        </Grid>
                        <Grid item md={12} sm={12} pt={2}>
                            <Text popupHead1 sx={{ font: "22px Quicksand !important", fontWeight: `${700} !important` }}>Timesheet Already Exists.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} pt={2}>
                            <Text offBoardBodyFont>Timesheet already drafted between the select dates,<br />Please select another date to continue.</Text>
                        </Grid>
                        <Grid container columnGap={3} display={"flex"} justifyContent={"center"} pt={3}>
                            <Button outlineBlueMedium1 sx={{ width: "124px !important", borderRadius: "8px !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, minHeight: "44px !important" }} onClick={() => { setOpenExists(false) }} >
                                Cancel
                            </Button>
                            <Button deleteButton sx={{ width: "124px !important", background: "#0C75EB !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, textTransform: "none !important", }} onClick={() => { handleNavigateListing() }} >Take me there</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={navigateOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "50px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={offBoardSave} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Your progress will not be saved</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button smallBlackOutline onClick={() => { setNavigateOpen(false) }} >
                                Cancel
                            </Button>
                            <Button smallBlue redBackground onClick={() => { handleNavigateListing() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={openSubmit}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "30px", }}>
                    <Grid container>
                        <Grid item md={12} sm={12} textAlign={"center"}>
                            <img src={timesheet_submit} alt='timesheet_submit' />
                        </Grid>
                        <Grid item md={12} sm={12} pt={2} textAlign={"center"}>
                            <Text offBoardHeadFont sx={{ fontSize: "18px !important", fontWeight: `${700} !important` }}>{(stateId.state?.id !== "" && ["Pending Approval", "Approved", "Rejected"].includes(stateId.state?.status)) ? "Timesheet Re-submitted Successfully!" : "Timesheet Submitted Successfully"}</Text>
                        </Grid>
                        <Grid item md={12} sm={12} pt={1} textAlign={"center"}>
                            <Text offBoardBodyFont>You have successfully{(stateId.state?.id !== "" && ["Pending Approval", "Approved", "Rejected"].includes(stateId.state?.status)) && " re-"} submitted the timesheet<br />
                                for approval.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} mt={3} textAlign={"center"}>
                            <Button sx={{ width: "125px !important", font: "15px Quicksand !important", fontWeight: `${600} !important` }} smallBlue redBackground onClick={() => { setOpenSubmit(false); handleNavigateListing() }} >
                                Done
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={rejectOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "30px", }}>
                    {
                        rejectSuccess ?
                            <Grid container>
                                <Grid item md={12} sm={12} textAlign={"center"}>
                                    <img src={timesheet_reject} alt='timesheet_reject' />
                                </Grid>
                                <Grid item md={12} sm={12} pt={2} textAlign={"center"}>
                                    <Text offBoardHeadFont sx={{ fontSize: "22px !important" }}>Timesheet Rejected!</Text>
                                </Grid>
                                <Grid item md={12} sm={12} pt={1} textAlign={"center"}>
                                    <Text offBoardBodyFont>You have rejected timesheet of {values.employee_name}</Text>
                                </Grid>
                                <Grid container item md={12} sm={12} mt={5} justifyContent={"center"}>
                                    <Button sx={{ width: "132px !important", height: "44px !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, borderRadius: "8px !important" }} saveBtn onClick={() => { handleNavigateListing() }} >Go To Home</Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid container>
                                <Grid item md={12} sm={12} textAlign={"center"}>
                                    <img src={rejectSVg} alt='rejectSVg' />
                                </Grid>
                                <Grid item md={12} sm={12} pt={2} textAlign={"center"}>
                                    <Text offBoardHeadFont sx={{ fontSize: "22px !important" }}>Are you sure?</Text>
                                </Grid>
                                <Grid item md={12} sm={12} pt={1} textAlign={"center"}>
                                    <Text offBoardBodyFont>Do you want to reject timesheet?</Text>
                                </Grid>
                                <Grid item md={12} sm={12} mt={3} >
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'rejectComment',
                                            value: rejectComment,
                                            inputProps: { maxLength: 150 }
                                        }}
                                        clientInput
                                        labelText={'Add reason for rejection'}
                                        handleChange={handleRejectChange}
                                        error={rejectError.rejectComment}
                                    />
                                    <Text red> {rejectError.rejectComment ? rejectError.rejectComment : ""}</Text>
                                </Grid>
                                <Grid container item md={12} sm={12} mt={3} justifyContent={"center"}>
                                    <Button sx={{ width: "85px !important", height: "44px !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, borderRadius: "8px !important" }} outlineBlueMedium1 onClick={() => { setRejectOpen(false) }} >Cancel</Button>
                                    <LoadingButton saveBtn sx={{ width: "124px !important", height: "44px !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, borderRadius: "8px !important", marginLeft: "20px !important" }} loading={rejectLoad} onClick={handleRejectTimesheet}>Yes, Reject</LoadingButton>
                                </Grid>
                            </Grid>
                    }
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={approvedOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "30px", }}>
                    <Grid container>
                        <Grid item md={12} sm={12} textAlign={"center"}>
                            <img src={timesheet_approved} alt='timesheet_approved' />
                        </Grid>
                        <Grid item md={12} sm={12} pt={2} textAlign={"center"}>
                            <Text offBoardHeadFont sx={{ fontSize: "22px !important" }}>Timesheet Approved!</Text>
                        </Grid>
                        <Grid item md={12} sm={12} pt={1} textAlign={"center"}>
                            <Text offBoardBodyFont>You have successfully approved timesheet.</Text>
                        </Grid>

                        <Grid container item md={12} sm={12} mt={5} justifyContent={"center"}>
                            <Button sx={{ width: "132px !important", height: "44px !important", font: "15px Quicksand !important", fontWeight: `${400} !important`, borderRadius: "8px !important" }} saveBtn onClick={() => { handleNavigateListing() }} >Go To Home</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={employeeOpen}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => setEmployeeOpen(false)}
            >
                <DialogContent sx={{ padding: "30px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} p={2}>
                        <Grid item md={12} sm={12} xs={12} textAlign={"center"}>
                            <img src={employee_notmatch} alt="employee_notmatch" />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                            <Text veryLargeLabel>Name Mismatch Alert!</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3} textAlign={"center"}>
                            <Text offBoardBodyFont>The uploaded data with this name won't reflect.<br />Please upload the document with the correct name.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3} textAlign={"center"}>
                            <Button onClick={() => { setEmployeeOpen(false) }} sx={{ width: "125px !important", font: "15px Quicksand !important", fontWeight: `${600} !important` }} smallBlue>Okay</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={documentOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ padding: "20px 0px", margin: "10px", textAlign: "center" }}>
                    <Grid container>
                        <Grid item md={12} sm={12}>
                            <img src={deleteAlert} alt='deleteAlert' />
                        </Grid>
                        <Grid item md={12} sm={12} pt={2}>
                            <Text LargeBlack>OOPS!</Text>
                        </Grid>
                        <Grid item md={12} sm={12} pt={2}>
                            <Text offBoardBodyFont>Please submit at least one document for our <br />AI does its magic!.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} pt={3} >
                            <Button outlineBlueMedium1 sx={{ width: "124px !important", borderRadius: "8px !important", fontWeight: `${400} !important`, minHeight: "44px !important" }} onClick={() => { setDocumentOpen(false) }} >
                                Ok
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={ocrOpen}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => setOcrOpen(false)}
            >
                <DialogContent sx={{ padding: "30px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} p={2}>
                        <Grid item md={12} sm={12} xs={12} textAlign={"center"}>
                            <img src={datefetched} alt="datefetched" />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                            <Text veryLargeLabel>Data Fetched from timesheet</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3} textAlign={"center"}>
                            <Text offBoardBodyFont>Our AI functionality did its job.<br />
                                Please recheck the data before submitting and <br />
                                You can manipulate the Start Date</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3} textAlign={"center"}>
                            <Button onClick={() => { setOcrOpen(false) }} sx={{ width: "125px !important", font: "15px Quicksand !important", fontWeight: `${600} !important` }} smallBlue>Done</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={employeeNotFound}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ padding: "30px", }}>
                    <Grid container justifyContent={'center'} alignContent={'center'} p={2}>
                        <Grid item md={12} sm={12} xs={12} textAlign={"center"}>
                            <img src={deleteAlert} alt="deleteAlert" />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                            <Text veryLargeLabel>Name Recognition Error!</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3} textAlign={"center"}>
                            <Text offBoardBodyFont>The uploaded document couldn't read the name.<br />Please try again.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} pt={3} textAlign={"center"}>
                            <Button onClick={() => { setEmployeeNotFound(false) }} sx={{ width: "125px !important", font: "15px Quicksand !important", fontWeight: `${600} !important` }} cancelOutlineBlue>Go Back</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <ReusablePopup openPopup={openZero} setOpenPopup={setOpenZero} white iconHide statusWidth>
                <Grid container alignItems='center' textAlign='center' spacing={2}>
                    <Grid item lg={12} xs={12} pb={2}>
                        <img src={emptyPrompts} alt="empty" />
                    </Grid>
                    <Grid item lg={12} xs={12} pb={2}>
                        <Text largeLabel>
                            Organization Credit Limit is Low. Please reach out to your organization point of contact.
                        </Text>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Button upgradePlan onClick={() => setOpenZero(false)}>Go back To Timesheets</Button>
                    </Grid>
                </Grid>
            </ReusablePopup>
        </Box >
    )
}

export default AddTimesheet