import { Box, Grid, Hidden, Stack, Checkbox } from '@mui/material';
import React, { useState, useEffect } from 'react';
// import CustomInput from '../../components/common/customInput/CustomInput';
// import Text from '../../components/common/customText/CustomText';
import Input from '../../components/input/Input';
import Text from '../../components/customText/Text'; // eslint-disable-next-line
import { isValid, validates_emailId, validate_charWithSpace, validate_usContact, validate_Char, validate_emptyField, empty_name, validate_alphaNumeric } from '../../components/Validation';
import LocalStorage from '../../utils/LocalStorage';
import SignUpStyles from './SignUpStyles'; // eslint-disable-next-line
import common_Api from '../../apis/CommonApi'; // eslint-disable-next-line
import { addErrorMsg, addSuccessMsg, capitalize } from '../../utils/utils';
import Popups from '../../components/customPopup/CustomPopups';
// components/common/customPopup/CustomPopups
import Otp from '../otp/OTP';
import { LoadingButton } from '@mui/lab';
import Select from '../../components/select/Select';
import genderOptions from '../../utils/jsons/Gender.json'
import Logo from '../../assets/logo.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../assets/svg/CheckedBorderIcon.svg';
import { useNavigate } from 'react-router-dom';
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"

function SignUp() {

  const classes = SignUpStyles();
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [otpCount, setotpCount] = useState('')
  const [error, setError] = useState({
    enable_login: false
  });// eslint-disable-next-line
  const [status, setStatus] = useState('create')
  const [state, setState] = useState({
    request_id: LocalStorage.uid(),
    first_name: '',
    middle_name: '',
    last_name: '',
    organization_name: '',
    email_id: '',
    mobile_number: '',
    gender: '',
    enable_login: false,
    subdomain_name: ''
  });

  const changeHandler = (e) => {
    if (e.target.name === 'enable_login') {
      state['enable_login'] = e.target.checked
      setState({ ...state })
      if (e.target.checked == true) {
        error.enable_login = false
        setError(error)
      }
    }
    else if (e.target.name == "mobile_number") {
      convertFormat(e)
    }
    else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
      setState({
        ...state,
        [e.target.name]: capitalize(e.target.value)
      }, handleValidate(e))
    }
    else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      },
        handleValidate(e));
    }
    if (e.target.name == 'email_id') {
      let value = validates_emailId(e.target.value)
      if (value == '') {
        let assign = e.target.value
        let dotValue = assign.split('.')[0].split('@')[1]
        let sampleData = {
          request_id: LocalStorage.uid(),
          email_id: e.target.value,
          subdomain_name: dotValue
        }
        common_Api.emailDomainCheck(sampleData).then((res) => {
          if (res.data.statusCode == 1003) {
            setError({
              ...error,
              email_id: res.data.data[0].generic_domain ? 'Please enter your work email' : res.data.data[0].email_exists ? 'Email already exists' : res.data.data[0].subdomain_name_exists ? 'This domain is already registered.' : res.data.data[0].email_not_verified ? `Looks like you haven’t verified your Email ID yet.` : ``
            })
          }
        })
      }
    }
  };

  const handleValidate = (e) => {
    let input = e.target
    switch (input.name || input.tagName) {
      case 'first_name':
        error.first_name = validate_charWithSpace(input.value, 'First ')
        break
      case 'middle_name':
        error.middle_name = empty_name(input.value, 'Middle ')
        break
      case 'last_name':
        error.last_name = validate_charWithSpace(input.value, 'Last ')
        break
      case 'email_id':
        error.email_id = validates_emailId(input.value)
        break
      case 'gender':
        error.gender = validate_emptyField(input.value)
        break
      case 'organization_name':
        error.organization_name = validate_alphaNumeric(input.value, 'Organization')
        break
      case 'mobile_number':
        error.mobile_number = validate_usContact(input.value, 'Mobile Number')
        break
      default:
        break
    }
    let err = { ...error }
    setError(err)
  }
  // eslint-disable-next-line
  const formValidations = () => {
    let { email_id, first_name, last_name, organization_name, mobile_number, gender, enable_login, middle_name } = state;
    let errors = {};
    errors.first_name = validate_charWithSpace(first_name, 'First ');
    errors.middle_name = empty_name(middle_name, 'Middle ')
    errors.last_name = validate_charWithSpace(last_name, 'Last ');
    errors.organization_name = validate_alphaNumeric(organization_name, 'Organization');
    errors.email_id = error.email_id == `This domain is already registered.` || error.email_id == `Email already exists` || error.email_id == `Looks like you haven’t verified your Email ID yet.` || error.email_id == 'Please enter your work email' ? error.email_id : validates_emailId(email_id);
    errors.mobile_number = validate_usContact(mobile_number, 'Mobile Number');
    errors.gender = validate_emptyField(gender);
    errors.enable_login = enable_login ? false : true;
    return errors;
  };

  /*submit fun*/
  const submitHandler = (e) => {
    e.preventDefault();
    let errors = formValidations();
    if (isValid(errors)) {
      setLoader(true);
      const data = state
      common_Api.storeSignUp(data).then((response) => {
        if (response.data.statusCode == 1003) {
          setLoader(false);
          setStatus('email-sent')
          setState({
            ...state,
            subdomain_name: response.data.subdomain_name
          })
          setotpCount('')
          // addSuccessMsg(`OTP send to your mail`);
          // setOpen(true);
        } else {
          setLoader(false)
          addErrorMsg(response.data.message);
        }
      })
    } else {
      let err = { error };
      err = errors;
      setError(err);
    }

  };


  const resend = () => {
    const data = {
      request_id: LocalStorage.uid(),
      subdomain_name: state.subdomain_name
    }
    common_Api.resendEmail(data).then((response) => {
      if (response.data.statusCode == 1003) {
        setotpCount(response.data.count);
        if (response.data.count >= 3) {
          navigate('/verify-account', { state: { status: 'attempts' } })
        }
        else {
          addSuccessMsg(`New Verification Link Sent Successfully`);
        }
        // setOpen(true);
      } else {
        setLoader(false)
        addErrorMsg(response.data.message);
      }
    })
  };

  /*onChange fun for input values*/
  const convertFormat = (e) => {
    const value = e.target.value;
    const input = value.replace(/\D/g, '').substring(0, 10);

    // Divide numbers in 3 parts :"(123) 456-7890" 
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      setState(
        {
          ...state,
          [e.target.name]: `${first}-${middle}-${last}`
        }, handleValidate(e));
    }
    else if (input.length > 3) {
      setState(
        {
          ...state,
          [e.target.name]: `${first}-${middle}`
        }, handleValidate(e));
    }
    else if (input.length >= 0) {
      setState(
        {
          ...state,
          [e.target.name]: input
        }, handleValidate(e));
    }
  }

  const ImagesList = [
    Image1,
    Image2,
    // Image3,
    Image4
  ];
  // eslint-disable-next-line
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    // Set an interval to update the current image every 3 seconds
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % ImagesList.length);
    }, 3000);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const valueGetter = (args) => {
    let a = args
    return 3 - a
  }

  return (
    <div>
      <Grid container>
        <Hidden mdDown>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{
            "@media (min-width: 300px) and (max-width: 1110px)": {
              height: 'fit-content'
            },
            height: '100vh',
            overflow: 'hidden'
          }}>
            <img src={ImagesList[currentImage]} alt={`img-${currentImage}`} width="100%" />
          </Grid>
        </Hidden>
        {
          status == 'create' ?
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} sx={{ height: '100vh', overflow: 'scroll' }}>
              <Box className={classes.boxOne}>
                <Box className={classes.boxTwo} >
                  {/* <form > */}

                  <Grid container xs={12} spacing={0}>

                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1} pt={5}>
                        <img src={Logo} alt='logo' />
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1} pt={2} pb={3}>
                        <Text popupHead1 sx={{ color: '#090914 !important' }}>Welcome to Japfu</Text>
                      </Box>
                    </Grid>

                    <Grid item sm={12} xs={12} >

                      <Box p={1}>
                        <Input
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'first_name',
                            value: state.first_name,
                            type: 'text',
                            inputProps: { maxLength: 33 }
                          }}
                          handleChange={changeHandler}
                          clientInput
                          labelText={'First Name'}
                          error={error.first_name}
                        />
                        <Text errorText> {error.first_name ? error.first_name : ""}</Text>
                      </Box>
                    </Grid>

                    <Grid item xs={12} >
                      <Box p={1}>
                        <Input
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'middle_name',
                            value: state.middle_name,
                            type: 'text',
                            inputProps: { maxLength: 33 }
                          }}
                          handleChange={changeHandler}
                          clientInput
                          labelText={'Middle Name (Optional)'}
                          error={error.middle_name}
                        />
                        <Text errorText> {error.middle_name ? error.middle_name : ""}</Text>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box p={1}>
                        <Input
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'last_name',
                            value: state.last_name,
                            type: 'text',
                            inputProps: { maxLength: 33 }
                          }}
                          handleChange={changeHandler}
                          clientInput
                          labelText={'Last Name'}
                          error={error.last_name}
                        />
                        <Text errorText> {error.last_name ? error.last_name : ""}</Text>
                      </Box>
                    </Grid>

                    <Grid item sm={12} xs={12}>
                      <Box p={1}>
                        <Select
                          name={'gender'}
                          options={genderOptions}
                          label={'Gender'}
                          value={state.gender}
                          onChange={changeHandler}
                          error={error.gender ? error.gender : ""}
                        />
                        <Text errorText> {error.gender ? error.gender : ""}</Text>
                      </Box>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Box p={1}>
                        <Input
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'organization_name',
                            value: state.organization_name,
                            type: 'text',
                            inputProps: { maxLength: 33 }
                          }}
                          handleChange={changeHandler}
                          clientInput
                          labelText={'Organization Name'}
                          error={error.organization_name}
                        />
                        <Text errorText> {error.organization_name ? error.organization_name : ""}</Text>
                      </Box>
                    </Grid>
                    <Grid item sm={12} xs={12} >
                      <Box p={1}>
                        <Input
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'email_id',
                            value: state.email_id,
                            type: 'text'
                          }}
                          handleChange={changeHandler}
                          clientInput
                          labelText={'Email ID'}
                          error={error.email_id}
                        />
                        <Text errorText> {error.email_id ? error.email_id : ""}</Text>
                      </Box>
                    </Grid>

                    <Grid item sm={12} xs={12} >
                      <Box p={1}>
                        <Input
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'mobile_number',
                            value: state.mobile_number,
                            type: 'text',
                            inputProps: {
                              maxLength: 12
                            }
                          }}
                          handleChange={changeHandler}
                          clientInput
                          labelText={'Mobile Number'}
                          error={error.mobile_number}
                        />
                        <Text errorText> {error.mobile_number ? error.mobile_number : ""}</Text>
                      </Box>

                    </Grid>



                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Box p={1}>
                      <Stack direction={'row'}>
                        <Checkbox name='enable_login' value={state.enable_login} checked={state.enable_login}
                          onChange={(e) => changeHandler(e)} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                          checkedIcon={<CheckedIcon />} />
                        <Text sx={{
                          font: '14px Quicksand !important',
                          color: '#1A141F !important',
                          "@media (min-width: 1200px) and (max-width: 1420px)": {
                            font: '14px Quicksand !important',
                          }
                        }}> &nbsp;&nbsp; I agree to the <a href='https://www.japfu.ai/terms-of-use' rel="noreferrer" target='_blank' style={{ textDecoration: 'underline', color: "#0C75EB" }}>terms of services</a> and <a href='https://www.japfu.ai/privacy-policy' rel="noreferrer" target='_blank' style={{ textDecoration: 'underline', color: "#0C75EB" }}>privacy policy</a></Text>
                      </Stack>
                    </Box>
                  </Grid>
                  {
                    error.enable_login ?
                      <Grid item sm={12} xs={12}>
                        <Box sx={{ background: '#FFFAF2', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center' }}>
                          <Text mediumOrange sx={{ fontSize: '12px !important' }}>Please read and accept the terms of service and privacy policy</Text>
                        </Box>
                      </Grid> : ''
                  }

                  <Box className={classes.buttonStyles}>
                    <LoadingButton
                      className={classes.loginButton}
                      loading={loader}
                      variant="contained"
                      // type="submit"
                      onClick={submitHandler}
                    >
                      Create Account
                    </LoadingButton>
                  </Box>
                  <Grid item container sm={12} xs={12} justifyContent={'center'}>
                    <Box p={1} pt={3}>
                      <Text largeLabel sx={{ fontWeight: '400 !important' }}>Already a member? <span onClick={() => { navigate('login') }} style={{ color: "#0C75EB", cursor: 'pointer' }}>Login</span></Text>
                    </Box>
                  </Grid>
                  <Grid item container sm={12} xs={12} justifyContent={'center'}>
                    <Box p={1}>
                      <Text largeLabel sx={{ fontWeight: '400 !important' }}><a href={'mailto:support@japfu.ai'} style={{ color: "#0C75EB" }}>Contact Support</a></Text>
                    </Box>
                  </Grid>
                  {/* </form> */}
                </Box>
              </Box>
            </Grid>
            :
            <Grid item lg={6} md={6} sm={12} xs={12} p={2} >
              <Box className={classes.boxOne}>
                <Box className={classes.boxTwo}>

                  <Grid container spacing={0}>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box height={'30vh'} />
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <img src={Logo} alt='logo' />
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text popupHead1 sx={{ color: '#090914 !important' }}>Verify your account</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pt={1}>
                        <Text grey16>Great! You are just a step away from accessing Japfu. Please</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={0}>
                        <Text grey16>check your inbox and click on the link to verify your email!</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text mediumBoldGrey>{state.email_id}</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text grey16 sx={{ color: '#AEAEAE !important' }}>Haven’t received the verification mail? <span onClick={() => { resend() }} style={{ color: '#0C75EB', cursor: 'pointer' }}>Resend</span></Text>
                      </Box>
                    </Grid>
                    {
                      otpCount != '' && otpCount != 0
                        ?
                        <Grid item sm={12} xs={12}>
                          <Box sx={{ background: '#FFFAF2', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center' }}>
                            <Text mediumOrange sx={{ fontSize: '12px !important' }}> Verification email sent, you have {otpCount != '' ? valueGetter(otpCount) : ''} more attempt</Text>
                          </Box>
                        </Grid>
                        : ''
                    }
                    <Grid item >
                      <Box height={'40vh'} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                      <Box p={1} pl={10}>
                        <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}><span onClick={() => { setStatus('create') }} style={{ color: '#0C75EB', paddingRight: '10px', cursor: 'pointer' }}>Change E-mail ID</span>
                          <span style={{
                            height: '6px',
                            width: '6px',
                            borderRadius: '50%',
                            background: '#D9D9D9',
                            display: 'flex',
                            margin: '8px 5px 2px 0px'
                          }}> </span>
                          <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
        }



      </Grid>
      <Popups
        title="Enter OTP"
        dialogTitleSmaller
        openPopup={open}
        setOpenPopup={setOpen}
      >
        <Otp email={state.email_id} signUp={"signup"} />
      </Popups>
    </div>
  )
}

export default SignUp
