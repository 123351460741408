import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import moment from "moment";
import Text from "../../../components/customText/Text";
import { dateFormat } from "../../../utils/utils";

function TimeSheetTable(props) {
    const { data, index, onChange, otHoursHandleChange, ocrTimesheetData, capture, formStatus } = props;

    function dateReturn(week, df) {
        if (formStatus == "add") {
            const momentDate = moment(week.date, dateFormat());
            if (momentDate.isValid()) {
                return momentDate.format(df);
            }
        } else {
            return moment(week.date).format(df);
        }
    }

    return (
        <Box key={index} sx={{ border: "1px solid #B0B0B0", borderRadius: "8px", marginTop: "20px" }}>
            <Table size="small" sx={{ borderRadius: "8px" }}>
                <TableHead>
                    <TableRow sx={{ height: '47px' }} key={index + 1}>
                        <TableCell sx={{ width: '140px', textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: props.isEdit ? "#EEEEEE" : "#ffffff", borderTopLeftRadius: "8px" }}><Text largeBlack sx={{ fontWeight: `${700} !important` }}>Timesheet {index + 1}</Text></TableCell>
                        {data.timesheet.map((week, k) => (
                            <TableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", borderBottom: " 1px solid #B0B0B0", textAlign: "center", backgroundColor: (dateReturn(week, 'ddd') === "Sat" || dateReturn(week, 'ddd') === "Sun") ? "#FCDCDC" : props.isEdit ? "#EEEEEE" : "#ffffff", }} key={k}>
                                <Text largeBlack sx={{ font: `14px Quicksand !important`, fontWeight: `${600} !important` }}>{week.date == "" ? null : dateReturn(week, 'ddd')}</Text>
                                <Text smallLabel sx={{ font: "12px Quicksand !important", color: "#404040 !important", fontWeight: `${400} !important` }}>{dateReturn(week, 'DD-MMM-YYYY')}</Text>
                            </TableCell>
                        ))}
                        <TableCell sx={{ width: '140px', textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: props.isEdit ? "#EEEEEE" : "#ffffff", borderTopRightRadius: "8px" }}> <Text largeBlack sx={{ fontWeight: `${700} !important` }}>Total</Text></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {/* Billable Hours */}
                    <TableRow sx={{ height: '47px' }} key={index + 2}>
                        <TableCell sx={{ padding: 0, textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: props.isEdit ? "#EEEEEE" : "#ffffff" }}><Text blackHeader1 sx={{ font: `14px Quicksand !important`, fontWeight: `${600} !important`, }}>Working Hours</Text></TableCell>
                        {data.timesheet.map((item, bIndex) => (
                            <TableCell key={bIndex} sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", borderBottom: " 1px solid #B0B0B0", padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Quicksand", backgroundColor: (dateReturn(item, 'ddd') === "Sat" || dateReturn(item, 'ddd') === "Sun") ? "#FCDCDC" : props.isEdit ? "#EEEEEE" : "#ffffff" }}>
                                <Box>
                                    <input type="text" value={item.billable_hours} maxLength={5}
                                        onChange={(event) => onChange(event.target.value, index, bIndex)}
                                        style={{
                                            color: "#404040", fontWeight: 400, font: "12px Quicksand", width: "74px", height: "25px", textAlign: "center", borderRadius: "30px",
                                            border: "none",
                                            backgroundColor: !capture ? "transparent" : capture && ocrTimesheetData != undefined && ocrTimesheetData.find((i) => moment(i.date).format(dateFormat()) == (formStatus == "add" ? item.date : moment(item.date).format(dateFormat()))) ? "#FAC94B" : capture && formStatus == "add" ? "#C6C5C2" : "transparent",
                                        }}
                                        disabled={props.isEdit}
                                    />
                                </Box>
                            </TableCell>
                        ))}

                        <TableCell sx={{ padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Quicksand", borderBottom: " 1px solid #B0B0B0", background: props.isEdit ? "#EEEEEE" : "#ffffff" }}>{data.billable_hours}</TableCell>
                    </TableRow>

                    {/* OT Hours */}
                    <TableRow sx={{ height: '47px' }} key={index + 3}>
                        <TableCell sx={{ padding: 0, textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: props.isEdit ? "#EEEEEE" : "#ffffff" }}><Text blackHeader1 sx={{ font: `14px Quicksand !important`, fontWeight: `${600} !important`, }}>OT Hours</Text></TableCell>
                        {data.timesheet.map((item, bIndex) => (
                            <TableCell key={bIndex} sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", borderBottom: " 1px solid #B0B0B0", padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Quicksand" }}>
                                <input
                                    type="text"
                                    value={item.ot_hours}
                                    maxLength={5}
                                    onChange={(event) => otHoursHandleChange(event.target.value, index, bIndex)}
                                    style={{ color: "#404040", fontWeight: 400, font: "12px Quicksand", width: "100%", height: "47px", textAlign: "center", border: "none", backgroundColor: (dateReturn(item, 'ddd') === "Sat" || dateReturn(item, 'ddd') === "Sun") ? "#FCDCDC" : props.isEdit ? "#EEEEEE" : "#ffffff", }}
                                    disabled={props.isEdit}
                                />
                            </TableCell>
                        ))}

                        <TableCell sx={{ padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Quicksand", borderBottom: " 1px solid #B0B0B0", background: props.isEdit ? "#EEEEEE" : "#ffffff" }}>{data.ot_hours}</TableCell>
                    </TableRow>

                    {/* Total Hours */}
                    <TableRow sx={{ height: '47px' }} key={index + 4}>
                        <TableCell sx={{ textAlign: "center", background: props.isEdit ? "#EEEEEE" : "#ffffff", borderBottomLeftRadius: "8px" }}><Text blackHeader1 sx={{ font: `14px Quicksand !important`, fontWeight: `${600} !important`, }}>Total Hours</Text></TableCell>
                        {data.timesheet.map((item, i) => (
                            <TableCell key={i} sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", backgroundColor: (dateReturn(item, 'ddd') === "Sat" || dateReturn(item, 'ddd') === "Sun") ? "#FCDCDC" : "#DCFCDC", color: "#404040", fontWeight: 400, font: "12px Quicksand" }}>
                                {item.total_hours}
                            </TableCell>
                        ))}
                        <TableCell sx={{ textAlign: "center", backgroundColor: "#037847", color: "#FFFFFF", borderRadius: "0px 0px 8px 0px", borderBottomRightRadius: "8px" }}>{data.total_hours}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </Box>
    );
}

export default TimeSheetTable;
