module.exports = {
    /* Development */
    // API_URL: 'https://workforce-dev-api.codetru.org/api/v1/',
    API_URL: 'https://workforce-api.codetru.org/api/v1/',
    AdminURL: process.env.REACT_APP_API_URL,
    client_URL: process.env.REACT_APP_API_URL,
    org_URL: process.env.REACT_APP_API_URL,
    employee_URL: process.env.REACT_APP_API_URL,
    placement_URL: process.env.REACT_APP_API_URL,
    timesheet_URL: process.env.REACT_APP_API_URL,
    SSE_URL: process.env.REACT_APP_API_URL,

    dev_url: process.env.REACT_APP_DOMAIN_URL,
    qa_url: process.env.REACT_APP_DOMAIN_URL,
    uat_url: process.env.REACT_APP_DOMAIN_URL,

    /*  OCR URL  */
    Chatbot_URL: 'https://chatbot.codetru.org/',
    // Socket_URL: 'https://japfu-organization-dev.codetru.org',
    ocr_URL: 'https://ocr.codetru.org',
    token: 'QIo5LzZf64tF3re7eeBiphJyK/1Vr34I0wnmKICo3Co3PwqY+Tr1OymZ5atK4CWzVOJQhSiBExuaFqPFswOacbLVUuZ9NKUGxRGauH0+GOa/SMbbIOXEXiZbGjJX1J/VRFaxajeRrbtbhzmvkaD8ShRM0J2OH3pfB5eHuhekdGV3vG6URq0FHZE9JEEFmYEp+q7bRrjlTMJEng2Y4HEDQld70mx8Rjbr1a4i/B8uYfkL983pR7JkZElzjaXF/KmN7O+DQ9FooffJyMT3Qkc/mapa6IWlblS4jByj/jg8qefrq3P8AQ4EpyC+E8in9Oz116Fl/Py1hlKz3HzDhQZHQg=='
}